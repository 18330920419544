import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { SitesAutoComplete } from "../../autoComplete/site/sites-autoComplete";

export function RoutingOperationForm(params: {
  code: string;
  onSubmit?: (values: any) => void;
}) {
  const { code, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: null, // 공정 명
      code: code, // 공정 코드
      siteCode: null, // 사업장코드
      fromLocationCode: null, // 자재 출고 창고코드
      toLocationCode: null, // 재공품 입고 창고코드
    },
  });


  return (
    <Box maw={320} mx="auto" w={400}>
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          withAsterisk
          label="공정 코드"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <TextInput
          withAsterisk
          label="공정 이름"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <SitesAutoComplete
          withAsterisk
          label="사업장코드"
          placeholder="siteCode"
          width={"100%"}
          {...form.getInputProps("siteCode")}
        />
        <LocationAutoComplete
          withAsterisk
          label="자재 출고 창고코드"
          width={"100%"}
          {...form.getInputProps("fromLocationCode")}
        />
        <LocationAutoComplete
          withAsterisk
          label="재공품 입고 창고코드"
          width={"100%"}
          {...form.getInputProps("toLocationCode")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button type="submit"
          disabled={
            !form.values.code ||
            !form.values.siteCode ||
            !form.values.fromLocationCode ||
            !form.values.toLocationCode
          }>Submit</Button>
      </form>
    </Box>
  );
}
