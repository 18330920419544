import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsDeferredLotInfoPostRequest, WmsDeferredLotInfoPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const DeferredLotInfo = Object.assign({}, FormMain, {
  Button: Button,
  Input: TextInput,
  Date: DateInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  ItemAutoComplete: ItemAutoComplete,
});

export const DeferredLotInfoForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  // const { mutate: updateMutate } = useInventoryMutation("deferredLotInfo");
  const queryClient = useQueryClient();
  const { mutate: deferredLotInfoMutate } = useMutation(
    (params: DefaultApiWmsDeferredLotInfoPostRequest) =>
      mutateInventories.deferredLotInfo(params).mutationFn(params as DefaultApiWmsDeferredLotInfoPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("inventories");
      }
    }
  )

  const form = useForm<Partial<WmsDeferredLotInfoPostRequest>>({
    initialValues: {
      itemCode: formatterProps.itemCode,
      locationCode: formatterProps.locationCode,
      quantity: formatterProps.quantity,
      lotId: formatterProps.lotId,
      lotInfo: {
        name: formatterProps.name,
        expiration: formatterProps.expiration,
      },
    },
  });

  const onSubmit = () => {
    try {
      const { itemCode, locationCode, quantity } = form.values;
      if (!itemCode && !locationCode && !quantity) return;

      deferredLotInfoMutate(
        {
          wmsDeferredLotInfoPostRequest: {
            itemCode:
              formatterProps.itemCode || (form.values.itemCode as string),
            locationCode:
              formatterProps.locationCode ||
              (form.values.locationCode as string),
            quantity: form.values.quantity as string,
            lotId: Number(formatterProps.lotId || form.values.lotId),
            lotInfo: {
              name: form.values.lotInfo?.name,
              expiration: form.values.lotInfo?.expiration,
            },
            actionFrom: 'WEB'
          },
        },
        {
          onSuccess: (res: { status: number }) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "재고 정보 재입력에 성공하였습니다.",
                "재고 정보 재입력 성공",
                "green"
              )
            } else {
              customAlert(
                "재고 정보 재입력에 실패하였습니다.",
                "재고 정보 재입력 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "재고 정보 재입력에 실패하였습니다.",
        "재고 정보 재입력 실패",
        "red"
      )
    }
  };

  return (
    <>
      <DeferredLotInfo.Wrapper>
        <DeferredLotInfo.ItemAutoComplete
          disabled
          label="품목코드"
          withAsterisk
          {...form.getInputProps("itemCode")}
          defaultValue={formatterProps?.itemCode}
        />
        <LocationAutoComplete
          label="창고 코드"
          withAsterisk
          {...form.getInputProps("locationCode")}
          defaultValue={formatterProps?.locationCode}
        />
        <DeferredLotInfo.Input
          label="수량"
          withAsterisk
          type="number"
          {...form.getInputProps("quantity")}
          defaultValue={formatterProps?.quantity}
        />
        <DeferredLotInfo.Input
          label="로트명"
          {...form.getInputProps("lotInfo.name")}
          disabled={!formatterProps?.itemUsesLotName}
        />
        <DeferredLotInfo.Date
          label="만료일"
          //   placeholder="만료일을 선택하세요."
          minDate={new Date()}
          valueFormat="YYYY-MM-DD HH:mm:ss"
          {...form.getInputProps("lotInfo.expiration")}
          disabled={!formatterProps?.itemUsesLotExpiration}
        />
        <DeferredLotInfo.ButtonBox>
          <DeferredLotInfo.Button color="gray" onClick={closeModal}>
            취소
          </DeferredLotInfo.Button>
          <DeferredLotInfo.Button onClick={onSubmit}>
            재고 정보 재입력
          </DeferredLotInfo.Button>
        </DeferredLotInfo.ButtonBox>
      </DeferredLotInfo.Wrapper>
    </>
  );
};
