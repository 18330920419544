import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { RoutingOutsourceAutoComplete } from "@/features/ui/autoComplete/routingOutsources/routingOutsources-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { ScmApiPurchaseOrderItemsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

export const PurchaseOrderItemCreateForm = () => {
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPostRequest) =>
            mutatePurchaseOrderItems.create(params).mutationFn(params as ScmApiPurchaseOrderItemsPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrderItems');
            }
        }
    );

    const form = useForm({
        initialValues: {
            orderDate: undefined,
            deliveryDeadline: undefined,
            itemCode: undefined,
            quantityOrdered: undefined,
            contractorName: undefined,
            equipmentCode: undefined,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrderItemsGetRequest: {
                        orderDate: form.values.orderDate!,
                        deliveryDeadline: form.values.deliveryDeadline!,
                        itemCode: form.values.itemCode!,
                        quantityOrdered: form.values.quantityOrdered!,
                        contractorName: form.values.contractorName!,
                        equipmentCode: form.values.equipmentCode!,
                        routingOutsourceId: 0 // 추후에 없애야함
                    }
                },
                {
                    onSuccess: (res) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "수주 등록에 성공하였습니다.",
                                "등록 성공",
                                "green"
                            )
                        } else {
                            customAlert(
                                "수주 등록에 실패하였습니다.",
                                "등록 실패",
                                "red"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(handleErrorResponse({ error, errorContext: '수주' }), '수주 등록 실패', 'red')
                    }
                }
            )
        } catch (e) {
            customAlert(
                "수주 등록에 실패하였습니다.",
                "등록 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                <Create.Input
                    label="거래처"
                    placeholder="거래처 입력"
                    {...form.getInputProps("contractorName")}
                />
                <ItemAutoComplete
                    label="품목코드"
                    placeholder="품목 선택"
                    itemType="PRODUCT,SEMI_PRODUCT"
                    {...form.getInputProps("itemCode")}
                />
                <Create.Input
                    label="수주 수량"
                    placeholder="수주 수량 입력"
                    {...form.getInputProps("quantityOrdered")}
                />
                <EquipmentAutoComplete
                    label="설비 코드"
                    placeholder="설비 선택"
                    {...form.getInputProps("equipmentCode")}
                />
                <RoutingOutsourceAutoComplete
                    label="외주처"
                    placeholder="외주 선택"
                    setName={(name: string) => form.setFieldValue("routingOutsourceId", name)}
                    {...form.getInputProps("routingOutsourceId")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="수주 등록 날짜"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납품 최종 기한"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        수주 생성
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};