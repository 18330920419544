import useGetDefectsQuery from "@/api/defect/useGetDefectsQuery";
import { OperationOutsourceWorkByEquipmentHeader, OperationOutsourceWorkByEquipmentHeaderActionBar, OperationOutsourceWorkByEquipmentTableActionBar } from "@/features/operationOutsourceWorkByEquipment";
import { OperationOutsourceWorkByEquipmentTable } from "@/features/operationOutsourceWorkByEquipment/components/OperationOutsourceWorkByEquipmentTable";
import { OperationOutsourceWorkByEquipmentProvider } from "@/features/operationOutsourceWorkByEquipment/context/OperationOutsourceWorkByEquipmentProvider";
import { CalendarModal } from "@/features/ui/modal/CalendarModal";
import { CreatePlanningModal } from "@/features/ui/modal/CreatePlanningModal";
import { DefectTypeModal } from "@/features/ui/modal/DefectTypeModal";
import styled from "@emotion/styled";
import { useMantineColorScheme } from "@mantine/core";

interface ColorThemeProps {
    isDarkMode: boolean;
}

export const OperationOutsourceWorkByEquipment: React.FC = () => {

    const { data } = useGetDefectsQuery({
        query: {
            $and: [
                { routingCode: { $eq: process.env.REACT_APP_ROUTING_CODE } },
            ],
        }
    });

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";

    const defectCodes = data?.data.rows;

    return (
        <OperationOutsourceWorkByEquipmentProvider>
            <OperationOutsourceWorkByEquipmentWrapper isDarkMode={isDarkMode}>
                <OperationOutsourceWorkByEquipmentHeaderActionBar />
                <OperationOutsourceWorkByEquipmentHeader />
                <OperationOutsourceWorkByEquipmentTableActionBar />
                <OperationOutsourceWorkByEquipmentTable defectCodes={defectCodes} />
            </OperationOutsourceWorkByEquipmentWrapper>
            <DefectTypeModal pageKey={"equipment"} defectCodes={defectCodes} />
            <CalendarModal pageKey={"equipment"} />
            <CreatePlanningModal pageKey={"equipment"} />
        </OperationOutsourceWorkByEquipmentProvider>
    );
};

const OperationOutsourceWorkByEquipmentWrapper = styled.div<ColorThemeProps>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  // background: ${(props) => (props.isDarkMode ? "#ffffff" : "none")};
`;
