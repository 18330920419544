// 전체코드

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Alert } from "@mantine/core";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
interface CustomAlertProps {
    message: string;
    color : string;
    title : string;
    onConfirm: () => void;
}

const CustomAlert = ({ message, color, title, onConfirm }: CustomAlertProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleConfirm = () => {
    setShowModal(false);
    setTimeout(() => onConfirm(), 1);
  };


  useEffect(() => {
    setTimeout(() => setShowModal(true), 1);
  }, []);
  return (
    <>
      {/* 아무곳이나 눌러도 알림 창 사라지게 */}
      <AlertModal.Container showModal={showModal} onClick={handleConfirm}>
        <AlertModal.Content>
          <Alert withCloseButton w={400} title={title} color={color}>{message}</Alert>
        </AlertModal.Content>
      </AlertModal.Container>
    </>
  );
};

const customAlert = (message: string, title: string, color: string) => {
  const handleConfirm = () => {
    const modalRoot = document.getElementById("modal-alert-portal-wrapper");
    if (modalRoot) modalRoot.remove();
  };
  if (typeof window !== "undefined") {
    const subDiv = document.createElement("div");
    subDiv.id = "modal-alert-portal-wrapper";
    document.body.appendChild(subDiv);

    const root = createRoot(subDiv);
    root.render(<CustomAlert title={title} color={color} message={message} onConfirm={handleConfirm} />);
    }
};

export default customAlert;

const AlertModal = {
  Container: styled.div<{ showModal: boolean }>`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    cursor: default;
    z-index: 9999;
    white-space: pre-wrap;

    ${({ showModal }) =>
      showModal &&
      css`
        opacity: 1;
        pointer-events: auto;
      `}
  `,

  Content: styled.div`
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    width: 100%;
    height: 7rem;
    position: relative;
  `,

  Message: styled.div`
    font-size: 1.2rem;
  `,
};
