import { createContext, useMemo, useState } from "react";

export interface OperationOutsourceWorkByEquipmentContextProps {
  site?: string;
  line?: string;
  date: Date | string;
  defectCodes: string[] | null;
}

export const OperationOutsourceWorkByEquipmentStateContext = createContext({
  site: "",
  line: "",
  date: new Date(),
  defectCodes: null,
});
export const OperationOutsourceWorkByEquipmentActionsContext = createContext("");

export const OperationOutsourceWorkByEquipmentProvider = ({ children }: any) => {
  const [OperationOutsourceWorkByEquipmentState, setOperationOutsourceWorkByEquipmentState]: any =
    useState<OperationOutsourceWorkByEquipmentContextProps>({
      site: "",
      line: "",
      date: new Date(),
      defectCodes: null,
    });

  const actions: any = useMemo(
    () => ({
      setDate: (date: Date) => {
        setOperationOutsourceWorkByEquipmentState((prev: any) => ({
          ...prev,
          date,
        }));
      },
      setSite: (site: string) => {
        setOperationOutsourceWorkByEquipmentState((prev: any) => ({
          ...prev,
          site,
        }));
      },
      setLine: (line: string) => {
        setOperationOutsourceWorkByEquipmentState((prev: any) => ({
          ...prev,
          line,
        }));
      },
      setDefectReason: (defectCodes: string[]) => {
        setOperationOutsourceWorkByEquipmentState((prev: any) => ({
          ...prev,
          defectCodes: defectCodes,
        }));
      },
    }),
    []
  );

  return (
    <OperationOutsourceWorkByEquipmentActionsContext.Provider value={actions}>
      <OperationOutsourceWorkByEquipmentStateContext.Provider value={OperationOutsourceWorkByEquipmentState}>
        {children}
      </OperationOutsourceWorkByEquipmentStateContext.Provider>
    </OperationOutsourceWorkByEquipmentActionsContext.Provider>
  );
};
