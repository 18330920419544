import { useErpTransferQuery } from "@/api/delivery/useErpTransferQuery";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { WmsLogsTable } from "@/features/wmsLogs/components/table";

import { Button } from "@mantine/core";
import { WmsLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconArrowElbowRight } from "@tabler/icons-react";
import { useState } from "react";

const WmsLogs = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    InventoryWrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    WmsLogsTable: WmsLogsTable,
});

export const WmsLogsPage = () => {

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [data, setData] = useState<WmsLogsGet200ResponseRowsInner>();
    const [id, setId] = useState<number[] | unknown>();

    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: WmsLogsGet200ResponseRowsInner,
        selectedRowsRef: any
    ) => {
        setIsRowBtnActive(formatterPropsData?.actionType === "TRANSFER");
        setData(formatterPropsData);

        if (selectedRowsRef.current) {
            const lastSet: number[] | unknown = Array.from(selectedRowsRef.current).slice(-1)[0];
            if (lastSet instanceof Set) {
                let idArray: number[] = [];
                lastSet.forEach((singleId) => {
                    idArray = idArray.concat(singleId);
                });
                setId(idArray);
            }
        }
    }
    
    const { mutate: transferMutate } = useErpTransferQuery(id as number[]);

    return (
        <>
            <WmsLogs.InventoryWrapper>
                <WmsLogs.ActionHeader>
                    <WmsLogs.ActionButtonBox>
                        <WmsLogs.ActionButton
                            color="violet"
                            rightIcon={<IconArrowElbowRight />}
                            disabled={!isRowBtnActive}
                            onClick={() => transferMutate()}
                        >
                            재고 이동 연동
                        </WmsLogs.ActionButton>
                    </WmsLogs.ActionButtonBox>
                </WmsLogs.ActionHeader>
                <WmsLogs.Header>
                    <WmsLogs.HeaderTitle>WMS작업 이력 조회</WmsLogs.HeaderTitle>
                    <WmsLogs.HeaderSubTitle>
                        우리회사에서 다루는 wms작업 이력을 조회합니다.
                    </WmsLogs.HeaderSubTitle>
                </WmsLogs.Header>
                <WmsLogs.WmsLogsTable onRowSelect={handleRowSelect} />
            </WmsLogs.InventoryWrapper>
        </>
    );
};