
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Tooltip } from "@mantine/core";

const WorkEquipmentCellWrapper = styled.div`
  box-sizing: border-box;
  padding: 7px 10px 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  width: auto;
  position: relative;
`;

const Line = styled.div`
  color: var(--blue-7, #1c7ed6);
  text-align: left;
  font-family: var(--text-700-sm-font-family, "NotoSans-Bold", sans-serif);
  font-size: var(--text-700-sm-font-size, 14px);
  line-height: var(--text-700-sm-line-height, 155%);
  font-weight: var(--text-700-sm-font-weight, 700);
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const Equipment = styled.div`
  color: var(--blue-7, #1c7ed6);
  text-align: left;
  font-family: var(--text-700-sm-font-family, "NotoSans-Bold", sans-serif);
  font-size: var(--text-700-sm-font-size, 14px);
  line-height: var(--text-700-sm-line-height, 155%);
  font-weight: var(--text-700-sm-font-weight, 700);
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

export const WorkEquipmentCell = (props: {
  lineName?: string;
  equipmentCode: string | any;
  equipmentName: string;
  equipmentId: number;
}): JSX.Element => {
  const { lineName, equipmentName, equipmentId, equipmentCode } = props;
  const { openModal } = useModal();

  const name = equipmentName ?? "-";
  const code = equipmentCode ?? "-";
  return (
    <WorkEquipmentCellWrapper>
      <Tooltip label={lineName}>
        <Line>{lineName}</Line>
      </Tooltip>
      <Tooltip label={`${name} (${code})`}>
        <Equipment
          onClick={() => {
            openModal(<EquipmentDetailForm equipmentCode={code} />, null, "");
          }}
        >
          {name}
        </Equipment>
      </Tooltip>
    </WorkEquipmentCellWrapper>
  );
};
