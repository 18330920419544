import { customFunctions } from "@/config/customFunction";
import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { WorkEquipmentCell } from "@/features/workByManagement/WorkEquipmentCell";
import { WorkNumberInputCell } from "@/features/workByManagement/WorkNumberInputCell";
import { WorkProgressCell } from "@/features/workByManagement/WorkProgressCell";
import { WorkQuantityCell } from "@/features/workByManagement/WorkQuantityCell";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner, WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useWorkActions } from "./hooks/useWorkActions";

type PageKeyType = 'equipment' | 'plan' | 'operationOutsource';

type ItemType<T extends PageKeyType> = T extends 'equipment'
    ? AuthSignupPost201ResponseEquipmentWorksInner : T extends 'plan' ? ProductionPlansGet200ResponseRowsInnerWorksInner : WorksItemGet200ResponseRowsInner;

interface Params<T extends PageKeyType> {
    rowData: ItemType<T>;
    pageKey: PageKeyType;
    socketCounter?: string | null;
    defectTypes: DefectTypeItem[] | null;
    bgColor?: string;
    formReset: boolean;
    children?: React.ReactNode;
}

// 작업지시 Row의 작업 상태별 색상 정의
const workStatusColors = {
    WAITING: "",
    WORKING: theme.colors?.green?.[0],
    PAUSED: theme.colors?.orange?.[0],
    DONE: theme.colors?.gray?.[4]
};

// 작업지시 Row의 작업 상태 기본 색상 (작업지시가 없을 때)
const defaultWorkStatusColor = theme.colors?.gray?.[4];

const baseFixedColumns = 5;
const baseFixedPlanColumns = 7;

export const WorkTableRow = <T extends PageKeyType>({ rowData, pageKey, defectTypes, bgColor, socketCounter, formReset }: Params<T>) => {
    const isPlan = pageKey === 'plan';

    let numRefs = isPlan ? baseFixedPlanColumns : baseFixedColumns;
    if (customFunctions.ADD_WORK_ID_COLUMN) numRefs++;
    if (customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN) numRefs++;
    if (customFunctions.ADD_DESCRIPTION_COLUMN) numRefs++;

    const refs = useRef<(HTMLTableHeaderCellElement | null)[]>([]);
    const [widths, setWidths] = useState<number[]>(new Array(numRefs).fill(0));

    const state = useWorkState();
    const actions = useWorkActions();
    const publish = usePub();
    const checkRef = useRef<HTMLInputElement>(null);
    const [checkedValues, setCheckedValues] = useState(false);

    const trackingStatus = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).trackingStatus;
    const lastWorkDate = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate
        ? dayjs((rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate).format('YYYY-MM-DD')
        : '';

    const [localSocketCounter, setLocalSocketCounter] = useState(socketCounter);
    const [opened, { toggle }] = useDisclosure(false);

    useEffect(() => {
        setLocalSocketCounter(socketCounter);
        handleQuantityChange({
            key: rowData.id?.toString() ?? "",
            value: socketCounter ?? rowData?.summary?.end ?? "0"
        });
    }, [socketCounter]);

    useEffect(() => {
        const newWidths = refs.current.map((ref) => (ref ? ref.offsetWidth : 0));
        setWidths(newWidths);
    }, [state.date, state.works, state.tempWorks]);

    const handleQuantityChange = ({ key, value }: { key: string; value: string }) => {
        const data = state.tempQuantity.find((quantity) => quantity.key === (rowData.id && rowData?.id.toString()));
        if (data) {
            data.value = value;
        } else {
            state.tempQuantity.push({ key, value });
        }
        actions.setTempQuantity(state.tempQuantity);
    };

    const handleDefectChange = ({ key, value }: { key: string; value: string }) => {
        const workData = state.tempDefects.find((defect) => defect.id === (rowData.id && rowData?.id.toString()));
        const data = workData?.defect.find((defect) => defect.key === key);
        if (data) {
            data.value = value;
        } else {
            workData
                ? workData.defect.push({ key, value })
                : state.tempDefects.push({ id: (rowData.id ? rowData.id.toString() : undefined), defect: [{ key, value }] });
        }
        actions.setTempDefects(state.tempDefects);
    };

    const checkWorkSync = (workSync: string | null) => {
        if (!workSync) return null;

        const workSyncArr = workSync.split(",");
        return workSyncArr.length > 1 ? "사외 외주 실적" : "실적";
    };

    useSub('checkAll', () => {
        setCheckedValues(true);
    });

    useSub('unCheckAll', () => {
        setCheckedValues(false);
    });

    useEffect(() => {
        if (formReset) {
            setCheckedValues(false);
        }
    }, [formReset]);

    const calculateLeftWidth = (index: number) => {
        return widths.slice(0, index).reduce((acc, width) => acc + width, 0);
    };

    return (
        <WorkTr color={rowData.id && trackingStatus ? workStatusColors[trackingStatus] : defaultWorkStatusColor}>
            <Td leftWidth={0} minWidth={3} width={4} ref={(el) => (refs.current[0] = el)}>
                <Flex align="center" justify="center">
                    <Checkbox
                        ref={checkRef}
                        checked={checkedValues}
                        onChange={(e) => {
                            if (e.target.checked) {
                                publish('check');
                                setCheckedValues(true);
                                actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => [...prevWorks, rowData]);
                            } else {
                                publish('unCheck');
                                setCheckedValues(false);
                                actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => prevWorks.filter(work => work?.id !== rowData?.id));
                            }
                        }}
                    />
                </Flex>
            </Td>
            {isPlan ? (
                <Td minWidth={5} width={8} leftWidth={calculateLeftWidth(1)} ref={(el) => (isPlan ? refs.current[1] = el : el)}>
                    <Text ta="right">{rowData.scheduleSeq}</Text>
                </Td>
            ) : null}
            {isPlan ? (
                <Td minWidth={12} width={20} leftWidth={calculateLeftWidth(2)} ref={(el) => (isPlan ? refs.current[2] = el : el)}>
                    <Text>{lastWorkDate}</Text>
                </Td>
            ) : null}
            {isPlan && customFunctions.ADD_WORK_ID_COLUMN && (
                <Td minWidth={6} width={10} leftWidth={calculateLeftWidth(3)} ref={(el) => (refs.current[3] = el)}>
                    <Text>{rowData?.id}</Text>
                </Td>
            )}
            {isPlan && customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN && (
                <Td minWidth={3} width={5} leftWidth={calculateLeftWidth(4)} ref={(el) => (refs.current[4] = el)}>
                    <Text>{checkWorkSync((rowData as AuthSignupPost201ResponseEquipmentWorksInner & { description: string | null })?.description)}</Text>
                </Td>
            )}
            <Td minWidth={12} width={20} leftWidth={calculateLeftWidth(5)} ref={(el) => (isPlan ? refs.current[5] = el : refs.current[1] = el)}>
                <WorkEquipmentCell data={{ equipmentCode: rowData.equipmentCode, equipmentName: rowData.equipmentName }} />
            </Td>
            {isPlan && customFunctions.ADD_DESCRIPTION_COLUMN && (
                <Td minWidth={6} width={10} leftWidth={calculateLeftWidth(6)} ref={(el) => (refs.current[6] = el)}>
                    <Flex direction="column">
                        <Text>{rowData?.erpWorkOrderSerl}</Text>
                        <Text>{rowData?.description}</Text>
                    </Flex>
                </Td>
            )}
            <Td minWidth={22} width={31} leftWidth={calculateLeftWidth(7)} ref={(el) => (isPlan ? refs.current[7] = el : refs.current[2] = el)}>
                <WorkProgressCell
                    data={{
                        itemName: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.name as string,
                        itemCode: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.code as string,
                        itemId: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.id as number,
                        itemSpec: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.spec as string,
                        workId: rowData.id,
                        routingCode: rowData.routingCode,
                        percent: rowData.summary?.percent ?? "0",
                        trackingStatus: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.trackingStatus,
                        purchaseOrderItem: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.purchaseOrderItem,
                    }}
                />
            </Td>
            <Td minWidth={18} width={31} leftWidth={calculateLeftWidth(8)} ref={(el) => (isPlan ? refs.current[8] = el : refs.current[3] = el)}>
                <WorkQuantityCell
                    data={{
                        targetQuantity: rowData.targetQuantity,
                        totalQuantity: rowData.summary?.totalQuantity,
                        todoQuantity: rowData.summary?.todoQuantity,
                        defectTotal: rowData.summary?.defectTotal,
                        unitText: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.unitText as string,
                    }}
                />
            </Td>
            <Td minWidth={8} width={14} leftWidth={calculateLeftWidth(9)} ref={(el) => (isPlan ? refs.current[9] = el : refs.current[4] = el)}>
                {rowData?.id ? (
                    <WorkNumberInputCell
                        data={{
                            key: rowData.id.toString(),
                            defaultValue: rowData?.summary?.end ?? "0",
                            value: localSocketCounter !== undefined && localSocketCounter !== null ? localSocketCounter : (
                                state.tempQuantity.find(data => data.key === rowData.id?.toString())?.value ??
                                rowData?.summary?.end ??
                                "0"
                            ),
                            onChange: handleQuantityChange,
                            formReset: formReset ?? false,
                        }}
                    />
                ) : null}
            </Td>
            {defectTypes?.map((defectType: DefectTypeItem) => (
                <Td key={defectType.value} minWidth={8} width={14} ref={(el) => (refs.current[10 + defectTypes.indexOf(defectType)] = el)}>
                    {rowData?.id ? (
                        <WorkNumberInputCell
                            data={{
                                key: defectType.value,
                                defaultValue: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "0",
                                value: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "",
                                onChange: handleDefectChange,
                                formReset: formReset ?? false,
                            }}
                        />
                    ) : null}
                </Td>
            ))}
        </WorkTr>
    );
};

export const Td = styled.td<{ minWidth?: number; width?: number; isNumber?: boolean; leftWidth?: number }>`
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
    width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
    position: ${(props) => (props.leftWidth !== undefined ? 'sticky' : '')};
    border-right: 0.0625rem solid rgb(222, 226, 230);
    border-bottom: 0.0625rem solid rgb(222, 226, 230);
    left: ${(props) => (props.leftWidth !== undefined ? `${props.leftWidth}px` : 'auto')};
    z-index: ${(props) => (props.leftWidth !== undefined ? 10 : 0)};
    background-color: ${(props) => (props.leftWidth !== undefined ? 'white' : 'transparent')};
`;


export const WorkTr = styled.tr`
    td {
       background-color: ${(props) => props.color};
    }
`;

export const TextEllipsis = styled<any>(Text)`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;