import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { PurchaseOrderItemCreateForm } from "@/features/purchaseOrderItem/form/createForm";
import { DeliveryForm } from "@/features/purchaseOrderItem/form/deliveryForm";
import { PurchaseOrderItemActionHeader } from "@/features/purchaseOrderItem/header/purchaseOrderItemActionHeader";
import { PurchaseOrderItemsTable } from "@/features/purchaseOrderItem/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { Button } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";

const PurchaseOrderItems = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    PurchaseOrderItemsTable: PurchaseOrderItemsTable,
    PurchaseOrderItemCreateForm: PurchaseOrderItemCreateForm,
    DeliveryForm: DeliveryForm,
    PurchaseOrderItemActionHeader: PurchaseOrderItemActionHeader,
    ProductionPlanWithWorksForm: ProductionPlanWithWorksForm
})

export const PurchaseOrderItemsPage = () => {

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const [data, setData] = useState<AuthSignupPost201ResponseEquipmentPurchaseOrderItem>({});
    const [id, setId] = useState<any[] | unknown>([])

    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
        selectedRowsRef: any
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(selectedRowsInform);
        const lastSet: any[] | unknown = Array.from(selectedRowsRef.current).slice(-1)[0];
        setId(lastSet)
    }

    return (
        <>
            <PurchaseOrderItems.Wrapper>
                <PurchaseOrderItems.PurchaseOrderItemActionHeader isRowBtnActive={isRowBtnActive} purchaseOrderItemId={id} data={data} />
                <PurchaseOrderItems.Header>
                    <PurchaseOrderItems.HeaderTitle>수주 리스트</PurchaseOrderItems.HeaderTitle>
                    <PurchaseOrderItems.HeaderSubTitle>
                        발주된 품목을 조회할 수 있는 페이지 입니다.
                    </PurchaseOrderItems.HeaderSubTitle>
                </PurchaseOrderItems.Header>
                <PurchaseOrderItems.PurchaseOrderItemsTable onRowSelect={handleRowSelect} />
            </PurchaseOrderItems.Wrapper>
        </>
    );
};