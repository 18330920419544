import { deleteERPOutsourcingOrder, deleteERPWorkOrder } from "@/api/productionPlan/usePostProductionPlanGetQuery";
import useWorksMutation from "@/api/works/useWorksMutation";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksUpdateForm } from "@/features/productionPlan/form/UpdateForm";
import customAlert from "@/features/ui/alert/alert";
import { WorkEquipmentCell } from "@/features/workByEquipment/components/WorkEquipmentCell";
import { WorkNumberCell } from "@/features/workByEquipment/components/WorkNumberCell";
import { WorkNumberInputCell } from "@/features/workByEquipment/components/WorkNumberInputCell";
import { WorkProgressCell } from "@/features/workByEquipment/components/WorkProgressCell";
import { useSub } from "@/hooks/usePubSub";
import { BASE_URL, ProductionInstance } from "@/instance/axios";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useSocket, useSocketEvent } from "socket.io-react-hook";
import { putCorrection } from "../api/equipmentCorrection";
import { WorkUpdateForm } from "./WorkUpdateForm";

const WorkRowWrapper = styled.tr<{ color: string }>`
  display: flex;
  td {
    background-color: ${(props) => props.color};
  }
  width: 100%;
`;

const Td = styled.td<{ minWidth?: number; width?: number; seq?: number }>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  padding: 7px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 0.0625rem solid ${theme.colors?.gray?.[3]};
  white-space : pre-wrap;
`;

type PageKeyType = 'equipment' | 'plan' | 'outsource';

type ItemType<T extends PageKeyType> = T extends 'equipment'
  ? AuthSignupPost201ResponseEquipmentWorksInner & { purchaseOrderItem: AuthSignupPost201ResponseEquipmentPurchaseOrderItem } : ProductionPlansGet200ResponseRowsInnerWorksInner;

interface Params<T extends PageKeyType> {
  item: ItemType<T>;
  checkedValues?: string[];
  handleCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defectCodes: readonly string[];
  onSave?: (item: ItemType<T>) => void;
  pageKey?: PageKeyType;
  setCheckedValues?: React.Dispatch<React.SetStateAction<string>>;
}


export function WorkRow<T extends PageKeyType>(params: Params<T>): JSX.Element {

  const { item, defectCodes, pageKey, checkedValues, handleCheckboxChange } = params;
  const { openModal } = useModal();
  const planQuantity = item?.targetQuantity ?? ""; // 계획 수량
  const totalQuantity = item?.summary?.totalQuantity ?? ""; // 총생산 수량
  const todoQuantity = item?.summary?.todoQuantity ?? ""; // 생산 잔량
  const defectQuantity = item?.summary?.defectTotal ?? ""; // 불량 수량
  const percent = item?.summary?.percent ?? ""; // 진행률
  const equipmentCode = item?.equipmentCode;
  const equipmentName = item?.equipmentName;
  const trackingStatus = (item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.trackingStatus;
  const scheduleSeq = item?.scheduleSeq ?? "";
  const erpWorkOrderSerl = item?.erpWorkOrderSerl ?? "";
  const purchaseOrderItem = item?.purchaseOrderItem;
  const lastWorkDate = (item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.lastWorkDate ?
    dayjs((item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.lastWorkDate).format('YYYY-MM-DD') :
    "";
  const description = (item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.description;

  const { mutate: deleteMutate } = useWorksMutation("delete");

  const form = useForm();

  const { socket, error } = useSocket(BASE_URL, {
    autoConnect: true,
  });

  const roomName = equipmentCode ? `EC_${equipmentCode}` : "";

  const { lastMessage } = useSocketEvent(socket, roomName);

  const equipmentCounter = lastMessage?.equipmentCounter
    ? lastMessage?.equipmentCounter
    : "";

  if (roomName) {
    socket.emit(
      "call",
      "socket.join",
      { room: roomName },
      function (err: any, res: any) {
        if (err) {
          console.error(err);
        }
      }
    );
  }

  useEffect(() => {
    doneNum.current = equipmentCounter;
  }, [equipmentCounter]);

  // 최초값 유지해야하고, 변경되고 있는 것도 유지해야 한다.
  const initialDoneQuantity = item?.summary?.end ?? "";
  const doneNum = useRef(equipmentCounter || initialDoneQuantity);

  const initialDefectQuantities: any = item?.summary?.defect ?? {};
  const defectQuantities: any = useRef({ ...initialDefectQuantities });

  // radio 버튼 선택 여부
  const radioRef = useRef<HTMLInputElement>(null);

  // 작업 현황에 따른 row 색상 표시
  const isWork = !!item.productionPlanId; // 작업지시 없을 시 옅은 회색 (gray-3)
  const isPaused = trackingStatus === "PAUSED";
  const isWorking = trackingStatus === "WORKING";
  const isDone = trackingStatus === "DONE"; // 작업지시 완료되었을 경우 진한 회색 (gray-4)
  // const isPlan = !!item.work?.targetQuantity; // 계획 없이 작업지시 내린 것. 계획수량으로 임시 체크 (TODO: 수정 필요) (red-9)
  let backgroundColor = "transparent";

  if (!isWork) {
    backgroundColor = `${theme.colors?.gray?.[4]}`;
  } else if (isWorking) {
    backgroundColor = `${theme.colors?.green?.[0]}`;
  } else if (isPaused) {
    backgroundColor = `${theme.colors?.orange?.[0]}`;
  } else if (isDone) {
    backgroundColor = `${theme.colors?.gray?.[3]}`;
  }

  function handleDefectChange({ k, value }: { k: string; value: string }) {
    // initialDefectQuantities[k].defectQuantity = value;
    // defectQuantities.current[k].defectQuantity = value;
    defectQuantities.current[k] = {
      ...defectQuantities.current[k],
      defectQuantity: value,
    };
  }

  function handleChange({ k, value }: { k: string; value: string }) {
    doneNum.current = value;
  }

  async function save(isAll: boolean) {
    let isSaved = false;
    const isSingleUpdate =
      radioRef.current?.checked &&
      item?.id === Number(radioRef.current?.value);
    if (item?.id !== undefined && (isAll || isSingleUpdate)) {
      if (Number(doneNum.current) !== 0) {
        if (
          doneNum.current !== equipmentCounter ||
          doneNum.current !== initialDoneQuantity
        ) {
          await ProductionInstance.worksWorkIdPerformancePut({
            workId: item.id,
            worksWorkIdPerformancePutRequest: { accQuantity: doneNum.current },
          })
          isSaved = true;
        }
        doneNum.current = 0;
      }
      // initialDefectQuantities와 달라진것들만 찾아내서 저장한다.
      const defectCodes = Object.keys(defectQuantities.current);
      let changedDefects = defectCodes.filter((defectCode) => {
        return (
          initialDefectQuantities[defectCode].defectQuantity !==
          defectQuantities.current[defectCode].defectQuantity
        );
      });

      if (changedDefects.length > 0) {
        for await (const defectCode of changedDefects) {
          let accQuantity = defectQuantities.current[defectCode].defectQuantity;
          try {
            await ProductionInstance.worksWorkIdDefectPut({
              workId: item.id,
              worksWorkIdDefectPutRequest: {
                accQuantity: accQuantity.toString(),
                defectCode,
              },
            });
            // 불량 수량 저장 후 초기화
            accQuantity = "0";
          } catch (e: any) {
            if (
              e.response?.data?.message ==
              "defectQuantity is greater than startQuantity"
            ) {
              alert(
                "불량 수량 저장에 실패했습니다. \n불량 수량이 완료 수량을 초과하는지 다시 확인해주세요"
              );
            }
          }
        }
        isSaved = true;
      }
      // 불량 수량 저장 후 가지고 있는 changedDefects 초기화
      changedDefects = [];

      if (isSaved) {
        params?.onSave && params?.onSave(item);
      }
      // 선택된 생산계획의 수정사항이 없는 경우
      // else {
      //   alert("생산계획의 수정사항이 없습니다.");
      // }
    }
    // 선택된 샌상계획이 없는 경우
    // else {
    //   alert("선택된 생산계획이 없습니다. ");
    //   return;
    // }
  }

  // 실적 체크 확인 함수 
  const checkWorkSync = (workSync: string | null) => {
    if (!workSync) return null;

    const workSyncArr = workSync.split(",");
    if (workSyncArr.length > 1) {
      return "사외 외주 실적"
    }

    return "실적";
  }

  useSub("modify", async () => {
    // modify 버튼 클릭 시, 선택된 radio 버튼에 해당하는 작업지시만 수정 가능하도록 수정 필요 -> 수정 모달
    const workData = radioRef.current?.checked && item?.id ? item : null;
    if (workData) {
      openModal(
        <WorkUpdateForm workRowData={workData} />,
        null,
        "작업 수정",
        true
      );
    }
  });

  useSub("delete", async () => {
    // delete 버튼 클릭 시, 선택된 radio 버튼에 해당하는 작업지시만 삭제 가능하도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;
    if (workData) {
      // TODO: confirm 대신 다른 방법으로 체크 필요
      // eslint-disable-next-line no-restricted-globals
      if (confirm("삭제하시겠습니까?")) {
        workData?.id && deleteMutate({ workId: workData?.id });
      }
    }
  });

  useSub("saveAmount", async (data) => {
    data ? await save(false) : await save(true);
  });

  useSub("inputMaterial", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;

    if (workData) {
      openModal(
        <WorkInputForm
          workRowData={workData}
        // workId={workData.work?.id}
        // itemCode={workData.work?.item.code}
        />,
        null,
        "원/부자재 투입",
        true
      );
    }
  });

  useSub("update", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;

    if (workData) {
      openModal(
        <ProductionPlanWithWorksUpdateForm width="600px" />,
        {},
        "생산계획 수정",
        true
      );
    }
  });


  useSub("update", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;

    if (workData) {
      openModal(
        <ProductionPlanWithWorksUpdateForm width="600px" />,
        {},
        "생산계획 수정",
        true
      );
    }
  });


  // 생산계획 삭제
  useSub("deleteWork", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;

    if (workData) {
      const result = await deleteERPWorkOrder(workData.id as number);

      if (result.data.success && result.data?.success.length) {
        const workStr = result.data.success.join(', ');
        customAlert(`작업번호 ${workStr}번 생산계획이 삭제되었습니다.`, '작업 삭제', 'info')
      } else if (result.data.fail && result.data?.fail.length) {
        const workStr = result.data.fail.join(', ');
        customAlert(`작업번호 ${workStr}번 생산계획이 삭제가 실패되었습니다.`, '작업 삭제', 'red')
      }
    }
  });

  // 외주 생산계획 삭제
  useSub("deleteOutsourcingWork", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;

    if (workData) {
      const outSourcingResult = await deleteERPOutsourcingOrder(workData.id as number);

      if (outSourcingResult.data.success && outSourcingResult.data?.success.length) {
        const workStr = outSourcingResult.data.success.join(', ');
        customAlert(`작업번호 ${workStr}번 사외 외주 생산계획이 삭제되었습니다.`, '작업 삭제', 'info')
      } else if (outSourcingResult.data.fail && outSourcingResult.data?.fail.length) {
        const workStr = outSourcingResult.data.fail.join(', ');
        customAlert(`작업번호 ${workStr}번 사외 외주 생산계획이 삭제가 실패되었습니다.`, '작업 삭제', 'red')
      }
    }
  });

  useSub("adjustStock", async () => {
    // item.work.id 와 radioRef.current.value에 해당되는 item만 열리도록 수정 필요
    const workData = radioRef.current?.checked && item?.id ? item : null;
    if (workData) {
      if (window.confirm('재고 보정을 진행하시겠습니까?')) {
        const result = await putCorrection(workData.id as number);
        if (result.data.length) {
          customAlert('재고 보정이 완료되었습니다.', '재고 보정', 'info')
        } else {
          customAlert('보정할 재고가 존재하지 않습니다.', '재고 보정', 'red')
        }
      }
    }
  });

  return (
    <WorkRowWrapper color={backgroundColor} key={item?.id}>
      {/* <RowWrapper> */}
      <Td minWidth={40} width={4} key={item?.id}>
        <Checkbox
          name={item?.id + ""}
          w="100%"
          ref={radioRef}
          value={item?.id}
          onChange={handleCheckboxChange}
          checked={checkedValues?.includes(item?.id + "")}
        />
      </Td>
      {
        pageKey !== "equipment" ? (
          <Td minWidth={56} width={4}>
            <Text align="right" w="100%">{scheduleSeq}</Text>
          </Td>
        ) : null
      }
      {
        pageKey !== "equipment" ? (
          <Td minWidth={180} width={20}>
            <Text w="100%">{lastWorkDate}</Text>
          </Td>
        ) : null
      }
      <Td minWidth={56} width={4}>
        {item?.id}
      </Td>
      <Td minWidth={56} width={4}>
        {checkWorkSync((item as AuthSignupPost201ResponseEquipmentWorksInner & { description: string | null })?.description)}
      </Td>
      <Td minWidth={104} width={20}>
        <WorkEquipmentCell
          equipmentCode={equipmentCode}
          equipmentName={equipmentName as string}
          equipmentId={item?.id as number}
        />
      </Td>
      {
        pageKey === "plan" ?
          <Td minWidth={104} width={14}>
            {erpWorkOrderSerl}
            {'\n'}
            {description}
          </Td> : pageKey === "outsource" ? <Td minWidth={104} width={14}></Td> : null
      }
      <Td minWidth={340} width={31} height={"auto"}>
        <WorkProgressCell
          purchaseOrderItem={purchaseOrderItem}
          trackingStatus={trackingStatus}
          percent={percent ?? ""}
          productName={(item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.name as string} // itemName
          itemCode={(item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.code} // itemCode
          routingCode={item?.routingCode + ""} // routingCode
          productId={(item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.id}
          workId={item?.id}
          itemSpec={(item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.spec}
        />
      </Td>
      <Td minWidth={280} width={31}>
        {item?.id ? (
          <WorkNumberCell
            planQuantity={planQuantity}
            todoQuantity={todoQuantity}
            totalQuantity={totalQuantity}
            // doneQuantity={item?.work?.summary?.end ?? ""}
            defectQuantity={defectQuantity}
            unitText={(item as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.unitText}
          />
        ) : null}
      </Td>
      <Td minWidth={120} width={14}>
        {item?.id ? (
          <WorkNumberInputCell
            k="accQuantity"
            value={equipmentCounter || initialDoneQuantity}
            onChange={handleChange}
          />
        ) : null}
      </Td>

      {defectCodes.map((defectCode, i) => {
        return (
          <Td minWidth={120} width={14} key={"cell_" + defectCode}>
            {item?.id ? (
              <WorkNumberInputCell
                key={defectCode}
                k={defectCode}
                value={
                  (item?.summary?.defect as any)?.[defectCode]?.defectQuantity ?? ""
                }
                onChange={handleDefectChange}
              />
            ) : null}
          </Td>
        );
      })}


    </WorkRowWrapper>
  );
}