import { ScmInstance } from "@/instance/axios";
import { PurchaseOrderItemsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export const GET_PurchaseOrderItems_QUERY_KEY = "/purchaseOrderItems";

const getPurchaseOrderItemsFetch = (params?: any) => {
    if (params) {
        const query: any = {};
        // query를 보내는 조건은 필터 검색을 할 때이다.
        if (params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // 검색내용이 있을 때만 search를 보내야 한다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return ScmInstance.purchaseOrderItemsGet({
            ...query,
            page: params.activePage ?? 1,
            pageSize: params.pageSize ?? 10,
            sort: params?.sort,
            populate : ["itemName", "calculateQuantity"]
        });
    } else return ScmInstance.purchaseOrderItemsGet({});
};

const useGetPurchaseOrderItemsQuery = (params?: any) => {
    return useQuery<AxiosResponse<PurchaseOrderItemsGet200Response>>({
        queryKey: params
            ? [
                GET_PurchaseOrderItems_QUERY_KEY,
                params.activePage,
                JSON.stringify(params.query),
                params.searchFields,
                params.search,
                params.populate
            ]
            : [GET_PurchaseOrderItems_QUERY_KEY],
        queryFn: () => (params ? getPurchaseOrderItemsFetch(params) : getPurchaseOrderItemsFetch()),
    });
};

export default useGetPurchaseOrderItemsQuery;