import { useGenerateOutsourceWork, usePurchaseOrderItem } from "@/api/productionPlan/usePostProductionPlanGetQuery"
import { GET_PurchaseOrderItems_QUERY_KEY } from "@/api/purchaseOrderItems/useGetPurchaseOrderItemsQuery"
import { useModal } from "@/features/modal/ModalStackManager"
import { PurchaseOrderItemCreateForm } from "@/features/purchaseOrderItem/form/createForm"
import { DeliveryForm } from "@/features/purchaseOrderItem/form/deliveryForm"
import { ActionButtonBox } from "@/features/standard/ActionButtonBox"
import { ActionHeader } from "@/features/standard/ActionHeader"
import { Main } from "@/features/standard/Main"
import customAlert from "@/features/ui/alert/alert"
import { PRODUCTION_PLAN_QUERY_KEY } from "@/features/workByProductionPlan/api/useGetProductionPlanQuery"
import { WORKS_GET_KEY } from "@/features/workByRows/api/useWorksGetQuery"
import { usePub } from "@/hooks/usePubSub"
import { Button } from "@mantine/core"
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem } from "@sizlcorp/sizl-api-document/dist/models"
import { IconPackage, IconPlus, IconTrash } from "@tabler/icons-react"
import { useQueryClient } from "react-query"
import { ProductionPlanWithPurchaseForm } from "../form/purchaseProductionPlanCreateForm"

const PurchaseOrderItemsAction = Object.assign({}, Main, {
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    PurchaseOrderItemCreateForm: PurchaseOrderItemCreateForm,
})

interface PurchaseOrderItemActionHeaderProps {
    isRowBtnActive: boolean;
    purchaseOrderItemId: [number] | unknown;
    data : AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
}
export const PurchaseOrderItemActionHeader = (params: PurchaseOrderItemActionHeaderProps) => {
    const { isRowBtnActive = false, purchaseOrderItemId, data } = params;
    const { openModal } = useModal();
    const { mutateAsync: purchaseOrder } = usePurchaseOrderItem();
    const { mutateAsync: generateOutsource } = useGenerateOutsourceWork();
    const publish = usePub();
    
    const queryClient = useQueryClient();

    // 수주 가져오기
    const getPurchaseOrderItem = async () => {

        try {
            const purchaseOrderItem : any = await purchaseOrder();

            // 쿼리를 다시 불러오게 하기
            queryClient.invalidateQueries(WORKS_GET_KEY);
            queryClient.invalidateQueries(PRODUCTION_PLAN_QUERY_KEY);
            queryClient.invalidateQueries(GET_PurchaseOrderItems_QUERY_KEY);

            const { insert: purchaseInsert, update: purchaseUpdate, delete: purchaseDelete } = purchaseOrderItem.data ?? { insert: [], update: [], delete: [] };
            const insertCount = (Array.isArray(purchaseInsert) ? purchaseInsert.length : 0);
            const updateCount = (Array.isArray(purchaseUpdate) ? purchaseUpdate.length : 0);
            const deleteCount = (Array.isArray(purchaseDelete) ? purchaseDelete.length : 0);

            const alertParts = [
                insertCount ? insertCount + "건 연동" : "",
                updateCount ? updateCount + "건 갱신" : "",
                deleteCount ? deleteCount + "건 삭제" : ""
            ];

            const countSum = insertCount + updateCount + deleteCount;
            
            const alertString = countSum === 0 ? `수주 연동이 가능한 항목이 없습니다.` : `수주가 ${alertParts.filter(part => part).join(", ")}되었습니다.`;
            customAlert(alertString, '생산계획 연동', 'info');

        } catch (e) {
            console.log(e)
        }
    }
    // // 외주 생산계획 생성하기
    let purchaseOrderItemIds: any;
    if (purchaseOrderItemId instanceof Set) {
        purchaseOrderItemIds = Array.from(purchaseOrderItemId);
    }
    // const getGenerateOutsource = async () => {

    //     try {
    //         const generateOutsourceWork: any = await generateOutsource({
    //             purchaseOrderItemId: purchaseOrderItemIds
    //         });

    //         const response = generateOutsourceWork.data ?? { insert: [], update: [], delete: [] };

    //         const alertString = `외주 생산계획 ${response.filter((part: any) => part).join(", ")}번이 생성되었습니다.`;
    //         customAlert(alertString, '외주 생산계획 연동', 'info');
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    return (
        <PurchaseOrderItemsAction.ActionHeader>
            <PurchaseOrderItemsAction.ActionButtonBox>
                <PurchaseOrderItemsAction.ActionButton
                    leftIcon={<IconPlus />}
                    onClick={() => openModal(
                        <PurchaseOrderItemsAction.PurchaseOrderItemCreateForm />,
                        null,
                        "수주 추가",
                        true
                    )}
                >
                    추가
                </PurchaseOrderItemsAction.ActionButton>
                <PurchaseOrderItemsAction.ActionButton
                    leftIcon={<IconPlus />}
                    color="orange"
                    disabled={!isRowBtnActive}
                    onClick={() => openModal(
                        <DeliveryForm formatterProps={data} />,
                        null,
                        "납품하기",
                        true
                    )}
                >
                    납품하기
                </PurchaseOrderItemsAction.ActionButton>
                <PurchaseOrderItemsAction.ActionButton
                    leftIcon={<IconPackage />}
                    color="orange"
                    onClick={getPurchaseOrderItem}
                >
                    수주 가져오기
                </PurchaseOrderItemsAction.ActionButton>
                <PurchaseOrderItemsAction.ActionButton
                    leftIcon={<IconPackage />}
                    color="orange"
                    onClick={()=> openModal(
                        <ProductionPlanWithPurchaseForm purchaseData={data} />,
                        null,
                        "외주 생산계획 생성",
                        true
                    )}
                    disabled={purchaseOrderItemIds?.length !== 1}
                >
                    외주 생산계획 생성하기
                </PurchaseOrderItemsAction.ActionButton>
            </PurchaseOrderItemsAction.ActionButtonBox>
            <PurchaseOrderItemsAction.ActionButton
                color="red"
                rightIcon={<IconTrash />}
                onClick={() => publish("deleteSelectedItems")}
            >
                선택된 행 삭제
            </PurchaseOrderItemsAction.ActionButton>
        </PurchaseOrderItemsAction.ActionHeader>
    )
}