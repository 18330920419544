import styled from "@emotion/styled";
import { ActionIcon, Input } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { MouseEventHandler, useEffect } from "react";

const WorkInputCellWrapper = styled.div<{ isDirty: boolean }>`
  box-sizing: border-box;
  padding: 7px 10px 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 110px;
  position: relative;
  ${({ isDirty }) =>
    isDirty
      ? `.mantine-Input-input {
  border-radius: 2px;
  border-style: solid;
  border-color: var(--grape-6, #be4bdb);
  border-width: 1px;
}`
      : ""};
`;

const LowerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
`;

const Message = styled.div`
  color: var(--red-6, #fa5252);
  text-align: left;
  font-family: var(
    --components-input-error-md-font-family,
    "OpenSans-Regular",
    sans-serif
  );
  font-size: var(--components-input-error-md-font-size, 14px);
  line-height: var(--components-input-error-md-line-height, 16px);
  font-weight: var(--components-input-error-md-font-weight, 400);
  position: relative;
  flex: 1;
`;

const CounterButtons = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-3);
`;

export const WorkNumberInputCell = (props: {
  value: string;
  k: string;
  onChange?: ({ k, value }: { k: string; value: string }) => void;
}): JSX.Element => {
  let { k, value: cellValue } = props;
  const form = useForm({ initialValues: { [k]: cellValue } });
  useEffect(() => {
    form.setInitialValues({ [k]: cellValue });
    // form.resetDirty({ [k]: cellValue });
  }, [cellValue, form, k]);

  const up: MouseEventHandler<HTMLButtonElement> = (e) => {
    const changeValue: string = String(BigInt(form.values[k]) + BigInt(1));
    form.setFieldValue(k, changeValue);
    props.onChange &&
      props.onChange({
        k,
        value: changeValue,
      });
  };

  const down: MouseEventHandler<HTMLButtonElement> = (e) => {
    const changeValue: string = String(BigInt(form.values[k]) - BigInt(1));
    form.setFieldValue(k, changeValue);
    props.onChange &&
      props.onChange({
        k,
        value: changeValue,
      });
  };

  const keyUp = (e: any) => {
    // 만약 바뀐 데이터가 기본값보다 작다면 기본값으로 초기화한다.
    if (BigInt(e.target.value) < BigInt(cellValue)) {
      e.target.value = cellValue;
    }
    form.setFieldValue(k, e.target.value as string);
    props.onChange &&
      props.onChange({
        k,
        value: String(e.target.value),
      });
  };

  return (
    <WorkInputCellWrapper isDirty={form.isDirty(k)}>
      <Input
        {...form.getInputProps(k)}
        defaultValue={cellValue}
        onChange={(e) => {
          // 만약 바뀐 데이터가 기본값보다 작다면 기본값으로 초기화한다.
          if (BigInt(e.target.value) < BigInt(cellValue)) {
            e.target.value = cellValue;
          }
          form.setFieldValue(k, e.target.value as string);
          props.onChange &&
            props.onChange({
              k,
              value: String(e),
            });
        }}
        onKeyUp={(e) => keyUp(e)}
        rightSection={
          <CounterButtons>
            <ActionIcon variant="default" size="xs" radius="xs" onClick={up}>
              <IconChevronUp stroke={1} />
            </ActionIcon>
            <ActionIcon
              variant="default"
              size="xs"
              radius="xs"
              onClick={down}
              disabled={BigInt(form.values[k]) <= BigInt(cellValue)}
            >
              <IconChevronDown stroke={1} />
            </ActionIcon>
            {/* <Button.Group orientation="vertical">
              <Button variant="default">
                <IconChevronUp onClick={up} />
              </Button>
              <Button variant="default">
                <IconChevronDown onClick={down} />
              </Button>
            </Button.Group> */}

            {/* disabled={BigInt(form.values[k]) <= BigInt(cellValue)} */}
          </CounterButtons>
        }
        rightSectionProps={{
          style: { width: "auto" },
        }}
        type="number"
        placeholder="수량입력"
      />
      <LowerWrapper style={{ display: form.isDirty(k) ? "block" : "none" }}>
        <Message>{form.isDirty(k) ? "저장 필요" : ""}</Message>
      </LowerWrapper>
    </WorkInputCellWrapper>
  );
};
