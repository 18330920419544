import customAlert from "@/features/ui/alert/alert"
import DefaultInstance from "@/instance/axios"
import { useMutation, useQueryClient } from "react-query"
import { GET_DELIVERY_QUERY_KEY } from "./useGetDeliveryQuery"

const ErpTransferQuery = (wmsLogId: number[]) => {
    return DefaultInstance.erpSyncPushPushStockMovePost({
        erpSyncPushPushStockMovePostRequest: {
            wmsLogIds: wmsLogId
        }
    })
}

export const useErpTransferQuery = (wmsLogId: number[]) => {
    const queryClient = useQueryClient();
    return useMutation(
        ["erp-transfer"],
        () => ErpTransferQuery(wmsLogId),
        {
            onSuccess: (res: any) => {
                if (Array.isArray(res.data)) {
                    const successItems = res.data.filter((item: any) => item.success === true);
                    const failedItems = res.data.filter((item: any) => item.success === false);

                    const successIdsString = successItems.map((item: any) => item.wmsLogIds).join(', ');
                    const failedIdsString = failedItems.map((item: any) => item.wmsLogIds).join(', ');

                    let message = '';
                    if (successIdsString) {
                        message += `로그 번호 ${successIdsString} 번 전송이 완료되었습니다.\n`;
                    }
                    if (failedIdsString) {
                        message += `로그 번호 ${failedIdsString} 번 전송이 실패하였습니다.`;
                    }

                    customAlert(message, 'ERP 전송', successIdsString ? 'green' : 'red');
                    queryClient.invalidateQueries(GET_DELIVERY_QUERY_KEY);
                }
            },
        }
    )
}