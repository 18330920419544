import styled from "@emotion/styled";

const WorkNumberCellWrapper = styled.div`
  box-sizing: border-box;
  padding: 7px 10px 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
`;

const UpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
`;

const LowerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
`;

const TargetQuantity = styled.div`
  color: var(--blue-9, #1864ab);
  text-align: right;
  font-family: var(
    --components-table-cell-font-family,
    "Inter-Regular",
    sans-serif
  );
  font-size: var(--components-table-cell-font-size, 14px);
  line-height: var(--components-table-cell-line-height, 22px);
  font-weight: var(--components-table-cell-font-weight, 400);
  position: relative;
  flex: 1;
`;

const TodoQuantity = styled.div`
  color: var(--orange-9, #d9480f);
  text-align: right;
  font-family: var(
    --components-table-cell-font-family,
    "Inter-Regular",
    sans-serif
  );
  font-size: var(--components-table-cell-font-size, 14px);
  line-height: var(--components-table-cell-line-height, 22px);
  font-weight: var(--components-table-cell-font-weight, 400);
  position: relative;
  flex: 1;
`;

const TotalQuantity = styled.div`
  // DoneQuantity
  color: var(--teal-9, #087f5b);
  text-align: right;
  font-family: var(
    --components-table-cell-font-family,
    "Inter-Regular",
    sans-serif
  );
  font-size: var(--components-table-cell-font-size, 14px);
  line-height: var(--components-table-cell-line-height, 22px);
  font-weight: var(--components-table-cell-font-weight, 400);
  position: relative;
  flex: 1;
`;

const DefectQuantity = styled.div`
  color: var(--red-9, #c92a2a);
  text-align: right;
  font-family: var(
    --components-table-cell-font-family,
    "Inter-Regular",
    sans-serif
  );
  font-size: var(--components-table-cell-font-size, 14px);
  line-height: var(--components-table-cell-line-height, 22px);
  font-weight: var(--components-table-cell-font-weight, 400);
  position: relative;
  flex: 1;
`;

function addCommasToDecimalString(input: string): string {
  if (input === "") return "0 ";

  const parts = input.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const WorkNumberCell = (props: {
  planQuantity: string | any;
  todoQuantity: string | any;
  totalQuantity: string | any;
  // doneQuantity: string;
  defectQuantity: string | any;
  unitText?: string;
}): JSX.Element => {
  const { planQuantity, todoQuantity, totalQuantity, defectQuantity, unitText } = props; // doneQuantity

  const targetQuantityWithCommas = addCommasToDecimalString(planQuantity);
  const todoQuantityWithCommas = addCommasToDecimalString(todoQuantity);
  const totalQuantityWithCommas = addCommasToDecimalString(totalQuantity); // doneQuantityWithCommas
  const defectQuantityWithCommas = addCommasToDecimalString(defectQuantity);

  return (
    <WorkNumberCellWrapper>
      <UpperWrapper>
        <TargetQuantity>{targetQuantityWithCommas} {unitText}</TargetQuantity>
        <TodoQuantity>{todoQuantityWithCommas} {unitText}</TodoQuantity>
      </UpperWrapper>

      <LowerWrapper>
        <TotalQuantity>{totalQuantityWithCommas} {unitText}</TotalQuantity>
        <DefectQuantity>{defectQuantityWithCommas} {unitText}</DefectQuantity>
      </LowerWrapper>
    </WorkNumberCellWrapper>
  );
};
