import styled from "@emotion/styled";
import { Paper, Title } from "@mantine/core";
import dayjs from "dayjs";
import { useContext } from "react";
import { OperationOutsourceWorkByEquipmentStateContext } from "../context/OperationOutsourceWorkByEquipmentProvider";
export const OperationOutsourceWorkByEquipmentHeader = () => {
  const OperationOutsourceWorkByEquipmentState: any = useContext(OperationOutsourceWorkByEquipmentStateContext);

  return (
    <>
      <OperationOutsourceWorkByEquipmentTitleWrapper withBorder>
        <Title size="h1" weight={700}>
          {dayjs(OperationOutsourceWorkByEquipmentState?.date ?? undefined).format("YYYY-MM-DD")}
        </Title>
      </OperationOutsourceWorkByEquipmentTitleWrapper>
      <TitleWrap withBorder>
        <MainTitle>{"외주 설비별 생산현황"}</MainTitle>
        <SubTitle>
          {"우리 회사의 외주 설비별 생산현황에 대해 조회할 수 있는 페이지 입니다."}
        </SubTitle>
      </TitleWrap>
    </>
  );
};

const OperationOutsourceWorkByEquipmentTitleWrapper = styled<any>(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const TitleWrap = styled<any>(Paper)`
  width: 100%;
  padding: 5px 12px;
  margin: 10px 0px;
`;

const MainTitle = styled(Title)`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 162.176%; /* 32.435px */
  letter-spacing: -0.4px;
`;

const SubTitle = styled(Title)`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
`;


