import DefaultInstance from "@/instance/axios";
import { ProductionPlansGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useQuery } from "react-query";

// 추후에 쿼리KEY를 관리할 예정이다.

export const PRODUCTION_PLAN_QUERY_KEY = "/getWorkByProductionPlan";

const getPlansFetch = (params?: any) => {
  const query: any = {};
  const { activePage, scheduledAt, defectCodes } = params;

  if (scheduledAt) {
    const date = dayjs(scheduledAt);
    const before = date.toISOString();
    const after = dayjs(before)
      .add(1, "day")
      .subtract(1, "seconds")
      .toISOString();

    query["query"] = JSON.stringify({
      $and: [
        {
          scheduledAt: {
            $between: [before, after],
          },
        },
      ],
    });
  }

  return DefaultInstance.productionPlansGet(
    {
      ...query,
      populate: ["works", "equipment", "itemUnit", 'purchaseOrderItem'],
      page: activePage ?? 1,
      pageSize: 10,
    },
    {
      params: {
        // 백에서 직렬화 처리 해줌
        defectCodes: defectCodes.map((data: any) => data.code) ?? ["null"],
        sort: "-createdAt",
      },
    }
  );
};

const useGetPlansQuery = (params?: any) => {
  return useQuery<AxiosResponse<ProductionPlansGet200Response, any>>({
    queryKey: [
      PRODUCTION_PLAN_QUERY_KEY,
      params?.activePage,
      params?.scheduledAt,
      params?.defectCodes,
    ],
    queryFn: () => getPlansFetch(params),
  });
};

export default useGetPlansQuery;
