import useWorkSitesQuery from "@/api/useGetSitesQuery";
import useSiteMutation from "@/api/useSitesMutation";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useWorkByEquipmentActions } from "@/features/workByEquipment/hook/useWorkByEquipmentActions";
import { useModal } from "@/hooks";
import styled from "@emotion/styled";
import { Box, Button, Menu, Select } from "@mantine/core";
import { IconCalendar, IconSettings } from "@tabler/icons-react";


export const OperationOutsourceWorkByEquipmentHeaderActionBar = () => {
  const { openModal } = useModal();
  const actions: any = useWorkByEquipmentActions();
  const { data, refetch } = useWorkSitesQuery({ firstRequest: false });
  const { mutate } = useSiteMutation();
  const rows = data?.data?.rows ?? [];

  return (
    <OperationOutsourceWorkByEquipmentActionWrapper>
      <LeftActionBar>
        <StyledSelect
          onClick={() => refetch()}
          data={rows?.map((row: any) => row.name) ?? []}
          placeholder="작업장을 선택해주세요"
          searchable
          creatable
          onChange={(value) => {
            actions.setSite(value);
          }}
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(name) => {
            mutate({
              code: name,
              name: name,
            });
            return "";
          }}
        />
        {/* <LineSelect
          placeholder="라인을 선택해주세요"
          data={["라인1", "라인2", "라인3"]}
          searchable
          onChange={(value) => {
            actions.setLine(value);
          }}
        /> */}
        <Button
          leftIcon={<IconCalendar />}
          onClick={() => {
            openModal({
              modalType: "Calendar",
            });
          }}
        >
          {"날짜선택"}
        </Button>
      </LeftActionBar>
      <RightActionBar>
        <Menu shadow="md" width={150} position="bottom-end">
          <Menu.Target>
            <Button color="gray" rightIcon={<IconSettings />}>
              Settings
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <MenuItem
              onClick={() => {
                openModal({
                  modalType: "Defect",
                });
              }}
            >
              Settings
            </MenuItem>
          </Menu.Dropdown>
        </Menu>
      </RightActionBar>
    </OperationOutsourceWorkByEquipmentActionWrapper>
  );
};

const OperationOutsourceWorkByEquipmentActionWrapper = styled<any>(Box)`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const StyledSelect = styled(Select)`
  color: var(--dark-9, #101113);
  /* components/input/text/xs */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 233.333% */

  & input {
    width: 180px;
    border: none;
    height: 32px;
    border-radius: 4px;
  }
`;

// const PlanBtn = styled<any>(Button)`
//   display: flex;
//   align-items: flex-start;
//   height: 32px;
//   border-radius: 4px;
// `;

// const SettingBtn = styled<any>(Button)`
//   display: flex;
//   padding: 1px 8px;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   background: inherit;
//   color: ${theme?.colors?.gray?.[6]};
//   height: 32px;

//   &:hover {
//     background-color: ${theme?.colors?.blue?.[1]};
//   }
// `;

const MenuItem = styled<any>(Menu.Item)`
  text-align: center;
`;
