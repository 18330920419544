import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import { DefectsGet200Response, DefectsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

// 추후에 쿼리KEY를 관리할 예정이다.

export const QUERY_KEY = "/defects";

const getDefectsFetch = (params?: any) => {
  if (params) {
    const query: any = {};
    // query를 보내는 조건은 필터 검색을 할 때이다.
    if (params.query) {
      query["query"] = JSON.stringify(params.query);
    }
    // 검색내용이 있을 때만 search를 보내야 한다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return DefaultInstance.defectsGet({
      ...query,
      page: params.activePage ?? 1,
      pageSize: params.pageSize ?? 10,
      sort: params?.sort ?? "-code",
    });
  } else return DefaultInstance.defectsGet({
    pageSize: 30
  });
};

const useGetDefectsQuery = (params?: any) => {
  return useQuery<AxiosResponse<DefectsGet200Response, any[]>>({
    queryKey: params
      ? [
        QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [QUERY_KEY],
    queryFn: () => (params ? getDefectsFetch(params) : getDefectsFetch()),
  });
};

export default useGetDefectsQuery;


const getAllDefectsFetch = () => {
  return DefaultInstance.defectsFindPost().then((res: AxiosResponse<DefectsGet200ResponseRowsInner[]>) => res.data);
}

export const useGetDefectsAllQuery = () => {
  return useQuery(
    ["defects-get-all"],
    () => getAllDefectsFetch(),
    {
      select: (data: DefectsGet200ResponseRowsInner[]) =>
        data?.map((defect) => {
          return {
            value: defect.code!,
            label: defect.name!,
          };
        }) ?? [],
    }
  );
}
