import styled from "@emotion/styled";
import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import React, { forwardRef, useEffect, useState } from "react";
import { PurchaseOrderItemsOption, usePurchaseOrderItemsAutoComplete, usePurchaseOrderItemsCode } from "./auto-useGetPurchaseOrderItemsQuery";

interface InventoryProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface PurchaseOrderItemsAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (lotId: string | null) => void;
  maxDropdownHeight?: number;
  workData?: any;
  itemCodes: string | undefined;
  width?: string;
}

export const PurchaseOrderProductionPlanAutoComplete = (params: PurchaseOrderItemsAutoCompleteProps) => {
  const {
    value: PurchaseOrderItemsCode,
    onChange,
    maxDropdownHeight,
    width,
    workData,
    itemCodes,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  

  // 기존 쿼리 수정 -> itemCode 선택하지 않았을 경우에는 itemCode 전체 데이터 할당. 아닐 경우 특정 itemCode를 배열로 만들어 할당.
  const query = {
    $and: [
      itemCodes ?
        { itemCode: itemCodes } : { itemCode: null },
    ],
  };

  const itemQuery = {
    $and: [
      PurchaseOrderItemsCode ?
        { id: PurchaseOrderItemsCode } : { id: null },
    ],
  };

  const { data: options } = usePurchaseOrderItemsAutoComplete(focused, query, searchKeyword);

  const { data : initialOptions } = usePurchaseOrderItemsCode(!!itemCodes, itemCodes ?? null, itemQuery);
  
  // itemCodes가 없을 경우 초기화
  useEffect(() => {
    if (itemCodes === undefined || itemCodes === null || itemCodes === "") {
      onChangeHandler(null);
    } else if (itemCodes === PurchaseOrderItemsCode) {
      onChangeHandler(itemCodes);
    }
  }, [itemCodes, PurchaseOrderItemsCode]);

  const selectOptions = [...(options ?? []), ...(initialOptions ?? [])].reduce(
    (unique: InventoryProps[], option: InventoryProps) => {
      return unique.some((u) => u.value === option.value)
        ? unique
        : [...unique, option];
    },
    []
  );

  let selectedItem = initialOptions?.find((item) => item.value === PurchaseOrderItemsCode);
  
  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e)
    onChange && onChange(e);
  };
  
  const SelectItem = forwardRef<HTMLDivElement, InventoryProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            {/* <Text fz="xs"> (name: {code})</Text> */}
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <Select
      styles={
        PurchaseOrderItemsCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      value={initialOptions && initialOptions[0]?.value}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={selectOptions}
      searchable
      clearable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={PurchaseOrderItemsInfo({
        PurchaseOrderItems: selectedItem as PurchaseOrderItemsOption,
        onChange: onChangeHandler,
      })}
      nothingFound="No options"
      {...etcParams}
    />
  );
};


const PurchaseOrderItemsInfo = (params: {
  PurchaseOrderItems?: PurchaseOrderItemsOption;
  onChange: (PurchaseOrderItemsCode: string | null) => void;
}) => {
  const { PurchaseOrderItems, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return PurchaseOrderItems ? (
    <PurchaseOrderItemsInfoLabel>
      <IconX size="1rem" onClick={clearHandler} />
    </PurchaseOrderItemsInfoLabel>
  ) : null;
};

const PurchaseOrderItemsInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

