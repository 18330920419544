import { useModal } from "@/features/modal/ModalStackManager";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { theme } from "@/styles/theme";
import { setFilteringDecimalPoint } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Badge, Box, Tooltip } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

const WorkProgressCellWrapper = styled.div`
  box-sizing: border-box;
  padding: 7px 10px 7px 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  position: relative;
`;

const Product = styled.div`
  color: var(--blue-7, #1c7ed6);
  text-align: left;
  font-family: var(--text-700-sm-font-family, "NotoSans-Bold", sans-serif);
  font-size: var(--text-700-sm-font-size, 14px);
  line-height: var(--text-700-sm-line-height, 155%);
  font-weight: var(--text-700-sm-font-weight, 700);
  display: flex;
  gap: 10px;

  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ProgressWrapper = styled.div`
  color: var(--blue-7, #1c7ed6);
  text-align: left;
  font-family: var(--text-700-sm-font-family, "NotoSans-Bold", sans-serif);
  font-size: var(--text-700-sm-font-size, 14px);
  line-height: var(--text-700-sm-line-height, 155%);
  font-weight: var(--text-700-sm-font-weight, 700);
  height: 40px;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .mantine-Progress-root {
    flex: auto;
  }
`;

const StatusWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
const StatusBar = styled.div`
  width: 80%;
  background-color: ${theme.colors?.gray?.[2]};
`;

const StatusText = styled.div`
  width: 20%;
`;

const Status = styled.div<{ width: number }>`
  background-color: ${theme.colors?.blue?.[6]};
  height: 3px;
  width: ${(props) => props.width}%;
  max-width: 100%;
`;

const SpanText = styled.span<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : theme.colors?.gray?.[7])};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.5px; /* 146.429% */
`;

const trackingStatusColor = {
  "WAITING": "gray.7",
  "WORKING": "green.7",
  "PAUSED": "orange.7",
  "DONE": "red.7",
  "DEFAULT": "",
}

export const WorkProgressCell = (props: {
  purchaseOrderItem?: AuthSignupPost201ResponseEquipmentPurchaseOrderItem
  percent: string | any;
  productName: string;
  routingCode: string;
  productId?: number | null;
  workId?: number;
  itemCode?: string;
  itemSpec?: string;
  trackingStatus: ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum | undefined;
}): JSX.Element | null => {
  const { openModal } = useModal();

  const { t } = useTranslation();

  const {
    productName,
    routingCode,
    productId,
    percent,
    workId,
    trackingStatus,
    itemCode,
    itemSpec,
    purchaseOrderItem
  } = props;
  if (!productId) {
    return null;
  }

  // 생산 잔량 계산
  // 진행률 다시 계산
  // 진행률 = 총생산 / 계획 * 100

  // Math.round((1 - Number(todoQuantity) / Number(targetQuantity)) * 100)
  return (
    <WorkProgressCellWrapper>
      <Tooltip label={`${productName} (${routingCode})`}>
        <Product
          onClick={() =>
            openModal(<WorkView workId={workId} />, null, "작업지시")
          }
        >
          <Box
            style={{
              display: "flex",
              flexDirection: 'column',
              gap: 5
            }}
          >
            <Badge color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}>{t(trackingStatus as ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum)}</Badge>
            {
              purchaseOrderItem ? <Badge color="violet">{'수주'}</Badge> : null
            }
          </Box>
          <div>
            <span>
              {routingCode}
            </span>
            <span>
              {productName}({itemCode})
            </span>
            <span>
              {itemSpec ?? "-"}
            </span>
          </div>
        </Product>
      </Tooltip>
      <ProgressWrapper>
        {/* <Progress value={percent} animate label={`${percent}%`} size="md" /> */}
        <StatusWrap>
          <StatusBar>
            <Status width={percent}></Status>
          </StatusBar>
          <StatusText>
            <SpanText>{setFilteringDecimalPoint(percent,2)}%</SpanText>
          </StatusText>
        </StatusWrap>
      </ProgressWrapper>
    </WorkProgressCellWrapper>
  );
};
