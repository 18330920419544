import { sites } from "@/api/sites/useSitesQuery";
import useGenerateDraftWorksQuery from "@/api/useGenerateDraftWorks";
import usePlansWithWorksMutation from "@/api/usePlansWithWorksMutations";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PurchaseOrderProductionPlanAutoComplete } from "@/features/ui/autoComplete/purchaseOrderItems/purchaseOrderProductionPlan-autoComplete";
import { RoutingOutsourceAutoComplete } from "@/features/ui/autoComplete/routingOutsources/routingOutsources-autoComplete";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { setDateFormatString } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import {
    Button,
    Flex,
    Group,
    Paper,
    Space,
    Stepper,
    Table,
    Text,
    TextInput,
    Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
    AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
    ProductionPlansWithWorksPostRequest,
    ProductionPlansWithWorksPostRequestWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

// interface ExtendedWorks extends ProductionPlansWithWorksPostRequestWorksInner {
//   operationOutsourceName: string | null | undefined;
// }

// interface ExtendedCreateProductionPlanWithWorks
//   extends CreateProductionPlanWithWorks {
//   works: Array<ExtendedWorks>;
// }

export function ProductionPlanWithPurchaseForm(
    params: Readonly<{
        dateString?: string;
        purchaseData: AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
    }>
) {
    const { dateString, purchaseData } = params;
    const [active, setActive] = useState(0);
    const { closeModal } = useModal();

    const { mutate } = usePlansWithWorksMutation();

    const { data: siteData } = useQuery(sites.get({
        query: {
            $and: [
                {
                    name: purchaseData?.contractorName,
                },
            ],
        },
    }));


    const form = useForm<
        Partial<ProductionPlansWithWorksPostRequest> & {
            scheduledAtDate: Date;
            siteCode?: string;
            siteName?: string;
        }
    >({
        initialValues: {
            itemCode: purchaseData?.itemCode,
            scheduledAtDate: dateString
                ? dayjs(dateString).startOf("D").toDate()
                : dayjs().startOf("D").toDate(),
            targetQuantity: purchaseData?.calculateQuantity?.subQuantity,
            works: [],
            sub: undefined,
            siteCode: siteData?.data?.rows && siteData?.data?.rows[0]?.code,
            siteName: purchaseData?.contractorName,
            purchaseOrderItemId: purchaseData?.id,
        },
    });

    useEffect(() => {
        if (siteData?.data?.rows) {
            form.setFieldValue("siteCode", siteData.data.rows[0]?.code);
        }
        if (purchaseData && purchaseData.id) {
            form.setFieldValue("purchaseOrderItemId", purchaseData.id);
        }

    }, [siteData, form.setFieldValue, purchaseData]);

    const queryDraftWorks = useGenerateDraftWorksQuery({
        itemCode: form.values.itemCode,
        targetQuantity: form.values.targetQuantity,
    });

    const { data: worksDraft } = queryDraftWorks;
    const isProductable = (worksDraft?.works || []).length !== 0;

    const nextStep = async () => {
        // worksDraft를 서버에서 얻어와야 한다.
        // productionPlansGenerateDraftWorksPost
        const { data } = await queryDraftWorks.refetch();
        // const newWorks = data?.works?.map((work) => {
        //   return {
        //     ...work,
        //     operationOutsourceName: null,
        //   };
        // });
        const newWorks = data?.works?.map((work) => {
            return {
                ...work,
                routingOutsourceName: purchaseData?.contractorName,
                equipmentCode: purchaseData?.equipmentCode,
                targetLocationCode: purchaseData?.targetLocationCode,
                routingOutsourceId: purchaseData?.routingOutsourceId,
                description: purchaseData?.description,
            };
        })

        const newSub = data?.sub?.result?.map((sub) => {
            return {
                itemCode: sub.itemCode,
                targetQuantity: sub.targetQuantity,
                works: sub.works.map((work) => {
                    return {
                        routingCode: work.routingCode,
                        targetQuantity: work.targetQuantity,
                        moldCode: data?.sub?.commonMoldCode,
                        routingOutsourceName: purchaseData?.contractorName,
                        equipmentCode: purchaseData?.equipmentCode,
                        targetLocationCode: purchaseData?.targetLocationCode,
                        description: purchaseData?.description,
                    };
                }),
                scheduledAt: form.values.scheduledAtDate.toISOString(),
                purchaseOrderItemId: form.values.purchaseOrderItemId
            };
        });
        form.setFieldValue("works", newWorks);
        form.setFieldValue("sub", newSub);
        setActive((current) => current + 1);
    };

    const prevStep = () =>
        setActive((current) => (current > 0 ? current - 1 : current));

    // 1페이지 -> 2페이지 이동 조건
    const isValidStep1 =
        { ...form.getInputProps("itemCode") }.value &&
        Number({ ...form.getInputProps("targetQuantity") }.value > 0) &&
        isProductable;

    // 2페이지 -> 최종 페이지 이동 조건
    const isValidStep2: boolean =
        active === 1 &&
        Array.isArray({ ...form.getInputProps("works") }.value) &&
        { ...form.getInputProps("works") }.value.every((element: any) => {
            return !!element.targetQuantity;
        });

    const onSave = () => {
        const { itemCode, scheduledAtDate, targetQuantity, works, purchaseOrderItemId } = form.values;
        // 패밀리 금형일 경우 sub 데이터 추가
        let additionalData;
        if (worksDraft?.sub && Object.keys(worksDraft?.sub).length) {
            additionalData = {
                commonMoldCode: worksDraft?.sub?.commonMoldCode,
                sub:
                    worksDraft?.sub &&
                    worksDraft.sub.result.map((row) => {
                        return {
                            itemCode: row.itemCode,
                            scheduledAt: scheduledAtDate.toISOString(),
                            targetQuantity: row.targetQuantity,
                            works: row.works,
                            purchaseOrderItemId: purchaseOrderItemId
                        };
                    }),
            };
        }

        mutate(
            {
                purchaseOrderItemId: purchaseOrderItemId,
                itemCode: itemCode as string,
                scheduledAt: scheduledAtDate.toISOString(),
                targetQuantity: targetQuantity as string,
                works: works as ProductionPlansWithWorksPostRequestWorksInner[],
                ...additionalData,
            },
            {
                onSuccess: (res) => {
                    if (res.status === 200) {
                        customAlert(
                            "생산계획 생성에 성공하였습니다.",
                            "생성 성공",
                            "green"
                        );
                        form.reset();
                        setActive(0);
                        closeModal(true);
                    } else {
                        customAlert("생산계획 생성에 실패하였습니다.", "생성 실패", "red");
                    }
                },
            }
        );
    };

    const setSiteName = (name: string) => {
        form.setFieldValue("siteName", name);
    };

    const setName = (i: number, name: string) => {
        form.setFieldValue(`works.${i}.routingOutsourceName`, name);
    };

    const setSubName = (i: number, j: number, name: string) => {
        form.setFieldValue(`sub.${i}.works.${j}.routingOutsourceName`, name);
    };

    return (
        <FormWrapper>
            <form onSubmit={form.onSubmit((values) => console.log(values))}>
                <Stepper active={active} breakpoint="sm">
                    <Stepper.Step
                        label="생산계획 정보 입력"
                        description="생산계획 정보를 입력합니다."
                    >
                        <Flex gap="lg" direction="column">
                            <Flex gap="lg" justify="center" align="center">
                                <DateInput
                                    withAsterisk
                                    popoverProps={{ withinPortal: true }}
                                    icon={<IconCalendar size="1.2rem" />}
                                    label="계획일자"
                                    description="생산 예정일자를 선택합니다."
                                    valueFormat="YYYY-MM-DD"
                                    w={'100%'}
                                    {...form.getInputProps("scheduledAtDate")}
                                />
                                <SitesAutoComplete
                                    label="작업장"
                                    description="생산할 작업장을 선택합니다."
                                    placeholder="작업장 선택"
                                    w={'100%'}
                                    {...form.getInputProps("siteCode")}
                                    setSiteName={(e: string) => setSiteName(e)}
                                />
                            </Flex>
                            <Flex gap="lg" justify="center" align="center">
                                <ItemAutoComplete
                                    withAsterisk
                                    label="제품 선택"
                                    description="생산할 제품을 선택합니다."
                                    placeholder="제품 선택"
                                    itemType="PRODUCT,SEMI_PRODUCT"
                                    w={'100%'}
                                    {...form.getInputProps("itemCode")}
                                />
                                <TextInput
                                    withAsterisk
                                    type="number"
                                    label="목표 수량"
                                    description="생산 목표 수량을 입력합니다."
                                    placeholder="목표 수량"
                                    w={'100%'}
                                    {...form.getInputProps("targetQuantity")}
                                />
                            </Flex>
                            <Flex gap="lg" >
                                <PurchaseOrderProductionPlanAutoComplete
                                    label="납품 할 수주 선택"
                                    description="납품 할 수주를 선택합니다."
                                    placeholder="수주 선택"
                                    w={'100%'}
                                    itemCodes={form.values.itemCode}
                                    {...form.getInputProps("purchaseOrderItemId")}
                                    defaultValue={purchaseData?.itemCode}
                                />
                            </Flex>
                            {!isProductable &&
                                form.values.itemCode &&
                                form.values.targetQuantity && (
                                    <WarningText>
                                        제품을 생산하기 위한 공정이 설정되어 있지 않아 생산을 계획할
                                        수 없습니다.
                                    </WarningText>
                                )}
                        </Flex>
                    </Stepper.Step>
                    <Stepper.Step
                        label="생성될 작업지시 확인"
                        description="생성될 작업지시 확인 후 정보를 입력합니다."
                    >
                        <TableWrapper>
                            <thead>
                                <tr>
                                    <th colSpan={6}>
                                        <Title order={3}>{form.values.purchaseOrderItemId ? '수주 생산계획' : '기본 생산계획'}</Title>
                                        <Text fz="sm">
                                            생산계획 일자:{" "}
                                            {setDateFormatString(form.values.scheduledAtDate)}
                                        </Text>
                                    </th>
                                </tr>
                                <tr>
                                    <Th flex={1}>일자</Th>
                                    <Th flex={2}>제품</Th>
                                    <Th flex={2}>공정</Th>
                                    <Th flex={2}>창고</Th>
                                    <Th flex={2}>설비</Th>
                                    <Th flex={2}>외주처</Th>
                                    <Th flex={1}>목표수량</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(form.values.works) &&
                                    form.values.works?.map((work, i) => {
                                        return (
                                            <tr key={i}>
                                                <Td flex={1}>
                                                    <DateInput
                                                        popoverProps={{ withinPortal: true }}
                                                        icon={<IconCalendar size="1.2rem" />}
                                                        valueFormat="YYYY-MM-DD"
                                                        placeholder={setDateFormatString(
                                                            form.values.scheduledAtDate
                                                        )}
                                                        clearable
                                                        {...form.getInputProps(`works.${i}.scheduledAt`)}
                                                    />
                                                </Td>
                                                <Td flex={2}>
                                                    <Text>{form.values.itemCode}</Text>
                                                </Td>
                                                <Td flex={1}>
                                                    <Text>{work.routingCode}</Text>
                                                </Td>
                                                <Td flex={2}>
                                                    <LocationAutoComplete
                                                        {...form.getInputProps(`works.${i}.targetLocationCode`)}
                                                    />
                                                </Td>
                                                <Td flex={2}>
                                                    <EquipmentAutoComplete
                                                        placeholder="설비 선택"
                                                        {...form.getInputProps(`works.${i}.equipmentCode`)}
                                                    />
                                                </Td>
                                                <Td flex={2}>
                                                    <RoutingOutsourceAutoComplete
                                                        placeholder="외주처 선택"
                                                        {...form.getInputProps(
                                                            `works.${i}.routingOutsourceId`
                                                        )}
                                                        value={work.routingOutsourceId?.toString()}
                                                        setName={(e: any) => setName(i, e)}
                                                    />
                                                </Td>
                                                <Td flex={1}>
                                                    <TextInput
                                                        type="number"
                                                        {...form.getInputProps(`works.${i}.targetQuantity`)}
                                                    />
                                                </Td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </TableWrapper>

                        {/* 패밀리 금형일 경우 sub 데이터 추가 */}
                        {Array.isArray(form.values.sub) ? (
                            <TableWrapper>
                                <thead style={{ width: "100%" }}>
                                    <tr>
                                        <Title order={3}>패밀리 금형 생산계획</Title>
                                    </tr>
                                    <tr>
                                        <Th flex={1}>일자</Th>
                                        <Th flex={2}>제품</Th>
                                        <Th flex={2}>공정</Th>
                                        <Th flex={2}>창고</Th>
                                        <Th flex={2}>설비</Th>
                                        <Th flex={2}>외주처</Th>
                                        <Th flex={1}>목표수량</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(form.values.sub) &&
                                        form.values.sub?.map((subData, i) => {
                                            return subData.works.map((work, j) => {
                                                return (
                                                    <tr key={j}>
                                                        <Td flex={1}>
                                                            <DateInput
                                                                popoverProps={{ withinPortal: true }}
                                                                icon={<IconCalendar size="1.2rem" />}
                                                                valueFormat="YYYY-MM-DD"
                                                                placeholder={setDateFormatString(
                                                                    form.values.scheduledAtDate
                                                                )}
                                                                clearable
                                                                {...form.getInputProps(
                                                                    `sub.${i}.works.${j}.scheduledAt`
                                                                )}
                                                            />
                                                        </Td>
                                                        <Td flex={2}>
                                                            <Text>{subData.itemCode}</Text>
                                                        </Td>
                                                        <Td flex={2}>
                                                            <Text>{work.routingCode}</Text>
                                                        </Td>
                                                        <Td flex={2}>
                                                            <LocationAutoComplete
                                                                {...form.getInputProps(`works.${i}.targetLocationCode`)}
                                                            />
                                                        </Td>
                                                        <Td flex={2}>
                                                            <EquipmentAutoComplete
                                                                placeholder="설비 선택"
                                                                {...form.getInputProps(
                                                                    `sub.${i}.works.${j}.equipmentCode`
                                                                )}
                                                            />
                                                        </Td>
                                                        <Td flex={2}>
                                                            <RoutingOutsourceAutoComplete
                                                                placeholder="외주처 선택"
                                                                {...form.getInputProps(
                                                                    `sub.${i}.works.${j}.routingOutsourceId`
                                                                )}
                                                                setName={(e: any) => setSubName(i, j, e)}
                                                            />
                                                        </Td>
                                                        <Td flex={1}>
                                                            <TextInput
                                                                type="number"
                                                                {...form.getInputProps(
                                                                    `sub.${i}.works.${j}.targetQuantity`
                                                                )}
                                                                defaultValue={subData.targetQuantity}
                                                            />
                                                        </Td>
                                                    </tr>
                                                );
                                            });
                                        })}
                                </tbody>
                            </TableWrapper>
                        ) : null}
                    </Stepper.Step>

                    <Stepper.Completed>
                        <Paper p="md" withBorder>
                            <Title order={3}>기본 생산계획</Title>
                            <Title order={5}>예상되는 생산 계획</Title>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>계획일자</th>
                                        <th>작업장</th>
                                        <th>제품</th>
                                        <th>목표 수량</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text>
                                                {dayjs(form.values.scheduledAt).format("YYYY-MM-DD")}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>{form.values.siteName}</Text>
                                        </td>
                                        <td>
                                            <Text>{form.values.itemCode}</Text>
                                        </td>
                                        <td>
                                            <Text ta="end">
                                                {setToLocaleString(form.values.targetQuantity)}
                                            </Text>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Space h="md" />
                            <Title order={5}>예상되는 작업 지시</Title>
                            <Table>
                                <thead>
                                    <tr>
                                        <Th width={20}>일자</Th>
                                        <Th width={10}>제품</Th>
                                        <Th width={10}>공정</Th>
                                        <Th width={15}>창고</Th>
                                        <Th width={15}>설비</Th>
                                        <Th width={20}>외주처</Th>
                                        <Th width={10}>목표수량</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(form.values.works) &&
                                        form.values.works?.map((work, i) => {
                                            return (
                                                <tr key={i}>
                                                    <Td width={20}>
                                                        <Text>
                                                            {dayjs(work.scheduledAt).format("YYYY-MM-DD")}
                                                        </Text>
                                                    </Td>
                                                    <Td width={10}>
                                                        <Text>{form.values.itemCode}</Text>
                                                    </Td>
                                                    <Td width={10}>
                                                        <Text>{work.routingCode}</Text>
                                                    </Td>
                                                    <Td width={15}>
                                                        <Text>{purchaseData.targetLocationName}</Text>
                                                    </Td>
                                                    <Td width={15}>
                                                        <Text>{purchaseData.equipmentName}</Text>
                                                    </Td>
                                                    <Td width={20}>
                                                        <Text>{work.routingOutsourceName}</Text>
                                                    </Td>
                                                    <Td width={10}>
                                                        <Text ta="end">
                                                            {setToLocaleString(work.targetQuantity)}
                                                        </Text>
                                                    </Td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </Paper>
                        {Array.isArray(form.values.sub)
                            ? form.values.sub.map((subData, i) => {
                                return (
                                    <>
                                        <Space h="md" />
                                        <Paper p="md" withBorder>
                                            <Title order={3}>패밀리 금형 생산계획</Title>
                                            <Title order={5}>예상되는 생산 계획</Title>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>계획일자</th>
                                                        <th>작업장</th>
                                                        <th>제품</th>
                                                        <th>목표 수량</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Text>
                                                                {dayjs(subData.scheduledAt).format(
                                                                    "YYYY-MM-DD"
                                                                )}
                                                            </Text>
                                                        </td>
                                                        <td>
                                                            <Text>{form.values.siteCode}</Text>
                                                        </td>
                                                        <td>
                                                            <Text>{subData.itemCode}</Text>
                                                        </td>
                                                        <td>
                                                            <Text ta="end">
                                                                {setToLocaleString(subData.targetQuantity)}
                                                            </Text>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Space h="md" />
                                            <Title order={5}>예상되는 작업 지시</Title>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <Th width={20}>일자</Th>
                                                        <Th width={10}>제품</Th>
                                                        <Th width={10}>공정</Th>
                                                        <Th width={15}>창고</Th>
                                                        <Th width={15}>설비</Th>
                                                        <Th width={15}>외주처</Th>
                                                        <Th width={10}>목표수량</Th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(subData.works) &&
                                                        subData.works?.map((work, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <Td width={20}>
                                                                        <Text>
                                                                            {dayjs(work.scheduledAt).format(
                                                                                "YYYY-MM-DD"
                                                                            )}
                                                                        </Text>
                                                                    </Td>
                                                                    <Td width={10}>
                                                                        <Text>{subData.itemCode}</Text>
                                                                    </Td>
                                                                    <Td width={10}>
                                                                        <Text>{work.routingCode}</Text>
                                                                    </Td>
                                                                    <Td width={15}>
                                                                        <Text>{purchaseData.targetLocationCode}</Text>
                                                                    </Td>
                                                                    <Td width={15}>
                                                                        <Text>{work.equipmentCode}</Text>
                                                                    </Td>
                                                                    <Td width={15}>
                                                                        <Text>{work.routingOutsourceName}</Text>
                                                                    </Td>
                                                                    <Td width={10}>
                                                                        <Text ta="end">
                                                                            {setToLocaleString(work.targetQuantity)}
                                                                        </Text>
                                                                    </Td>
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </Table>
                                        </Paper>
                                    </>
                                );
                            })
                            : null}
                    </Stepper.Completed>
                </Stepper>

                <Group position="right" mt="xl">
                    {active !== 0 && (
                        <Button variant="default" onClick={prevStep}>
                            Back
                        </Button>
                    )}
                    {active === 0 && (
                        <Button onClick={nextStep} disabled={!isValidStep1}>
                            다음: 작업지시 만들기
                        </Button>
                    )}
                    {active === 1 && (
                        <Button
                            onClick={() => {
                                setActive((current) => current + 1);
                            }}
                            disabled={!isValidStep2}
                        >
                            미리보기
                        </Button>
                    )}
                    {active === 2 && <Button onClick={onSave}>생산계획 생성</Button>}
                </Group>
            </form>
        </FormWrapper>
    );
}

const FormWrapper = styled.div`
  width: 60rem;
`;

const TableWrapper = styled(Table)`
  /* width: 500px; */
  /* height: 300px; */
  margin-top: 4px;
  width: 100%;
`;

const Th = styled.th<{ width?: number, flex?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  flex: ${(props) => (props.flex ? `${props.flex}` : `1`)};
`;

const Td = styled.td<{ width?: number, flex?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  flex: ${(props) => (props.flex ? `${props.flex}` : `1`)};
`;

const WarningText = styled<any>(Text)`
  color: var(--red-6, #fa5252);
  /* components/input/error/xs */
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
