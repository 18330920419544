import { useMutation, useQuery, useQueryClient } from "react-query";

import { ProductionInstance } from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

export const GET_EQUIPMENT_CORRECTION_QUERY_KEY = "get-equipment-correction";

// 재고 보정
export const putCorrection = (param?: number) => {
    if (param) {
        return ProductionInstance.equipmentsCorrectionPut({
            equipmentsCorrectionPutRequest: {
                workId: param
            }
        });
    } else return Promise.reject("equipmentCode is not provided");
}

// 재고 보정 mutation
export const usePutCorrection = (param?: number) => {
    const queryClient = useQueryClient();
    const putMutation = useMutation(
        (params: number) => putCorrection(params),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(GET_EQUIPMENT_CORRECTION_QUERY_KEY);
            },
        }
    );
    return putMutation;
}

// 재고 소모 보정량 확인
export const getCorrection = (param?: number) => {
    if (param) {
        return ProductionInstance.worksWorkIdCheckCorrectionGet({
            workId: param
        });
    } else return Promise.reject("workId is not provided");
};

// 재고 소모 보정량 확인 query
export const useCheckCorrection = (param?: number) => {
    return useQuery<AxiosResponse<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[], any[]>>({
        queryKey: [GET_EQUIPMENT_CORRECTION_QUERY_KEY, JSON.stringify(param)],
        queryFn: () => getCorrection(param),
        enabled: !!param,
    });
};

