import DefaultInstance from "@/instance/axios";
import { WorksGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useQuery } from "react-query";

export const WORKS_GET_KEY = "/works";

const fetchWorksWorkGet = (
  defectCodes: string[],
  activePage: number,
  equipmentCode?: string,
  itemCode?: string,
  scheduledAt?: any,
  routingOutsourceId?: boolean
) => {
  const query: any = {};
  if (scheduledAt) {
    const [beforeDate, afterDate] = scheduledAt;

    const before = dayjs(
      dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
    ).toISOString();

    const after = afterDate
      ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
      : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

    const date = after ? [before, after] : [before, before];
    query["$and"] = [
      {
        lastWorkDate: {
          $between: date,
        },
      },
      ...(equipmentCode ? [{
        equipmentCode: {
          $eq: equipmentCode
        }
      }] : []),
      ...(itemCode ? [{
        itemCode: {
          $eq: itemCode
        }
      }] : []),
      ...(routingOutsourceId ? [{
        routingOutsourceId: {
          $ne: null
        }
      }] : []),
    ];
  }

  return DefaultInstance.worksGet(
    {
      query: JSON.stringify(query),
      page: activePage ?? 1,
      pageSize: 10,
    },
    {
      params: {
        defectCodes: defectCodes?.map((data: any) => data.code) ?? ["null"],
        populate: [
          "summary",
          "item",
          "equipment",
          "productionPlan",
          "routingData",
          "purchaseOrderItem"
        ],
        sort: "-createdAt",
      },
    }
  );
};

export const useWorksGet = (
  {
    defectCodes = [],
    activePage,
    scheduledAt,
    equipmentCode,
    itemCode,
    routingOutsourceId
  }: { defectCodes: string[]; activePage: number; scheduledAt?: Date, equipmentCode?: string, itemCode?: string, routingOutsourceId?: boolean },
  options?: {
    onSuccess: (work: WorksGet200Response) => void;
  }
) => {
  return useQuery(
    [WORKS_GET_KEY, defectCodes, activePage, equipmentCode, itemCode, scheduledAt, routingOutsourceId],
    () => fetchWorksWorkGet(defectCodes, activePage, equipmentCode, itemCode, scheduledAt, routingOutsourceId),
    {
      select: (data: AxiosResponse<WorksGet200Response, any>) =>
        data.data as WorksGet200Response,
      onSuccess: options?.onSuccess,
    }
  );
};