import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { usePub } from "@/hooks";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";

export const OperationOutsourceWorkByEquipmentTableActionBar = () => {
  const { openModal, closeModal } = useModal();
  const publish = usePub();

  const createWorkBtn = () => {
    openModal(
      <ProductionPlanWithWorksForm width="600px" />,
      {},
      "생산계획 생성",
      true
    );
  };

  return (
    <WorkByEquipmentTableActionBarWrapper>
      <LeftActionBar>
        <CreatePlanBtn onClick={createWorkBtn}>{"생산계획 생성"}</CreatePlanBtn>
        <CreateMaterialBtn
          onClick={() => {
            publish("inputMaterial");
          }}
        >
          {"원/부자재 투입"}
        </CreateMaterialBtn>
      </LeftActionBar>
      <RightActionBar>
        <CommonBtn
          color="yellow"
          onClick={() => {
            publish("modify");
          }}
        >
          {"수정"}
        </CommonBtn>
        <CommonBtn
          color="red"
          onClick={() => {
            publish("delete");
          }}
        >
          {"삭제"}
        </CommonBtn>
        <CommonBtn
          onClick={() => {
            publish("saveAmount");
          }}
        >
          {"모두 저장"}
        </CommonBtn>
        <CommonBtn
          onClick={() => {
            publish("saveAmount", "unique");
          }}
        >
          {"저장"}
        </CommonBtn>
      </RightActionBar>
    </WorkByEquipmentTableActionBarWrapper>
  );
};

const WorkByEquipmentTableActionBarWrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  // background: var(--gray-0, #f8f9fa);
`;

const CommonBtn = styled<any>(Button)`
  display: flex;
  padding: 1px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 242.857% */
`;

const CreatePlanBtn = styled<any>(CommonBtn)`
  background: var(--blue-6, #228be6);
  color: var(--other-white, #fff);
`;
const CreateBtn = styled(CommonBtn)`
  background: var(--gray-2, #e9ecef);
  color: var(--gray-5, #adb5bd);
  /* components/button/sm */
`;
const CreateMaterialBtn = styled<any>(CommonBtn)`
  background: var(--blue-0, #e7f5ff);
  color: var(--blue-6, #228be6);
`;
