import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Button,
  Flex,
  Pagination,
  useMantineColorScheme
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { ledgers } from "@/api/ledgers/useLedgersQuery";
import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { LedgersHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useLedgersState } from "@/features/ledgersAll/hook/useLedgersState";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import DatetimeUtil from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { LedgersGet200ResponseRowsInner, WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

export interface Row {
  id: number;
  itemCode: string;
  itemData: {
    user_lot_expiration?: string;
    user_lot_name?: string;
    unit_text?: string;
  };
  locationCode: string;
  locationData: {
    site_code?: string;
    updated_at?: string;
    name: string
  };
  lotId: number;
  lotData: {
    name?: string;
    expiration?: string;
  };
  quantity: string;
  userId: number;
  userCode: string;
  name: string; // userName
  createdAt: string;
  additional: {
    productionPlanId: number;
    workLogId: number;
  }
  workLogData: {
    id: number;
    workLogType: string;
    defectCode: string;
    defectName: string;
  }
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

interface Additional {
  productionPlanId: number;
  workLogId: number;
  workId: number;
}

export const LedgersAllTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { openModal } = useModal();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [formatterPropsData, setFormatterPropsData] = useState<any>([]);

  let state: any = useLedgersState();

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });
  const updateUrlParams = useUpdateUrlParams();

  const createdAt = {
    ...query,
    createdAt: { $between: [state.Date[0], state.Date[1]] },
  };

  const searchFieldsHeader = LedgersHeader.filter(
    (ledger) => ledger.category === "text"
  );

  const { t } = useTranslation();

  const { data: ledger, refetch } = useQuery(ledgers.get({
    query: createdAt,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : searchFieldsHeader.map((ledger) => ledger.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id",
    populate: ["item", "location", "name"],
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (ledger && activePage > (ledger?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [ledger, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly LedgersGet200ResponseRowsInner[] = ledger?.data.rows ?? [];

  const additional: Additional[] = rows
    .map((row) => row.additional)
    .filter((item): item is Additional => item !== undefined);

  const { data: workLogData } = useQuery(workLogs.get({
    query: {
      $and: [
        { id: additional.map((row) => row?.workLogId).filter(Boolean) }
      ]
    },
    populate: ["itemUnit", "defectName", "alreadyCanceled", "creatorUser", "downtimeReasonName", "getEquipment"],
  }))

  // rows에 workLogData를 추가
  const rowsWithWorkLogData = rows.map(row => {
    const matchingWorkLogData = workLogData?.data.rows?.find((workLog: WorkLogsGet200ResponseRowsInner) => workLog.id === (row.additional as Additional)?.workLogId);
    return {
      ...row,
      workLogData: matchingWorkLogData
    };
  });

  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  useEffect(() => {
    const selectedRowId = selectedRows.values().next().value;
    if (selectedRowId) {
      const selectedRow = rows.find((row) => row.id === selectedRowId);

      setFormatterPropsData(selectedRow);
    } else {
      setFormatterPropsData([]);
    }
  }, [selectedRows]);

  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={row.itemCode} />,
            null,
            "재고 상세"
          );
        };
        return <DetailLink onClick={detailItemAction}>{row.itemCode}</DetailLink>;
      },
    },
    {
      key: "locationCode",
      name: "위치",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <LocationDetailForm locationCode={row.locationCode ?? ""} />,
            null,
            "창고 상세"
          );
        };
        return (
          <DetailLink
            onClick={detailItemAction}
          >
            {row.locationData?.name}
          </DetailLink>
        );
      },
    },
    {
      key: "locationData.site_code",
      name: "사업장",
      sortable: true,
      resizable: true,
      formatter: ({ row }) =>
        <DetailLink
          onClick={() =>
            openModal(
              <SiteDetailForm siteCode={row.locationData.site_code} />,
              null,
              ""
            )
          }
        >
          {row.locationData?.site_code}
        </DetailLink>,
    },
    {
      key: "userCode",
      name: "사용자",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        return (
          <DetailLink onClick={() => openModal(<UsersDetailForm UserCode={row.userCode} />, null, "")}>
            {row.name}
          </DetailLink>
        )
      }
    },
    {
      key: "lotData.name",
      name: "로트명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row?.lotData?.name}</div>,
    },
    {
      key: "lotData.expiration",
      name: "로트 유효기한",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <div>
          {row?.lotData?.expiration === null
            ? ""
            : timeUtil(row.lotData?.expiration!) === "NaN-NaN-NaN"
              ? ""
              : timeUtil(row.lotData?.expiration!)}
        </div>
      ),
    },
    {
      key: "additional.productionPlanId",
      name: "생산계획번호",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => <DetailLink onClick={() => openModal(
        <ProductionPlanViewForm ProductionPlanId={row?.additional?.productionPlanId} />,
        null,
        "생산계획 상세"
      )}>{row?.additional?.productionPlanId}</DetailLink>,
    },
    {
      key: "workLogData.workLogType",
      name: "상세",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const defectName = row.workLogData?.defectName === null ? "" : ` (${row.workLogData?.defectName})`;
        return (
          <span>
            {row.workLogData?.workLogType ? t(row.workLogData?.workLogType) + defectName : ''}
          </span>
        )
      }
    },
    {
      key: "quantity",
      name: "재고 변화량",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => <span>{setToLocaleString(row.quantity)} {row.itemData?.unit_text}</span>,
    },
    {
      key: "createdAt",
      name: "입출고일시",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <span>{DatetimeUtil(row.createdAt)}</span>,
    },
  ];

  return (
    <LedgersTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={LedgersHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={LedgersHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rowsWithWorkLogData as any}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              selectedRowsRef.current = e;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={ledger?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </LedgersTableWrapper>
  );
};

const LedgersTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;

const DateSelectBtn = styled<any>(Button)`
  display: flex;
  align-items: flex-start;
  height: 36px;
  border-radius: 4px;
  margin-right: 20px;
`;
