import useWorkByEquipmentQuery from "@/features/workByEquipment/api/useWorkByEquipmentQuery";
import { WorkRow } from "@/features/workByEquipment/components/WorkRow";
import { useWorkByEquipmentState } from "@/features/workByEquipment/hook/useWorkByEquipmentState";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";

import { Pagination, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useState } from "react";

type WorkByEquipmentFlatRow = {
  id: number;
  siteCode: string;
  code: string;
  name?: string;

  equipmentType?: string | null;
  seq?: number;
  work?: {
    id: number;
    targetQuantity?: string | null;

    routingCode: string;
    status: string;
    summary?: {
      start: string;
      end: string;
      defect: Record<
        string,
        {
          defectQuantity: string;
          reworkQuantity: string;
          actualDefectQuantity: string;
        }
      >;
      totalQuantity: string;
      defectTotal: string;
      todoQuantity?: string;
      percent?: string;
    };
    [key: string]: any;
  };
  [key: string]: any;
};

export const OperationOutsourceWorkByEquipmentTable = ({ defectCodes }: any) => {
  const [activePage, setActivePage] = useState(1);
  const state: any = useWorkByEquipmentState();
  const usedDefectCodes = state?.defectCodes ?? defectCodes;

  const result = useWorkByEquipmentQuery("workByEquipment", {
    defectCodes: usedDefectCodes,
    activePage: activePage,
    scheduledAt: dayjs(new Date(state.date)).format("YYYY-MM-DD"),
    query: {
      // 외주로 Filtering 할수 있는 데이터가 백에 필요함
      $and: [
        // { routingCode: { $eq: process.env.REACT_APP_ROUTING_CODE } },
      ],
    }
  });

  // state.date가 변경되는지 감시하다가 다시 로드해야 한다.
  // useEffect(() => {
  //   result.refetch();
  // }, [state.date]);

  const rows = result.data?.data.rows;
  const totalPage = result?.data?.data?.totalPages;

  const reload = () => {
    result.refetch();
  };

  // rows 내의 works 를 reducer를 사용해서 flat하게 풀어낸다,
  // 예를 들어, row에 works가 없는 경우에는 row가 1개, works가 있는 경우에는 works의 갯수만큼 행이 증가해야 한다.
  const flattedRows: WorkByEquipmentFlatRow[] =
    rows?.reduce<WorkByEquipmentFlatRow[]>((acc, cur) => {
      const { works = [], ...equipmentInfo } = cur;

      // const works = cur.works ?? []
      if (works.length === 0) {
        acc.push(equipmentInfo);
      } else {
        works.forEach((work) => {
          const { summary, ...eachWork } = work;
          const {
            start,
            end,
            defect,
            defectTotal,
            todoQuantity,
            percent,
            totalQuantity,
          } = summary ?? {};
          acc.push({
            ...equipmentInfo,
            work: {
              id: work.id as number,
              routingCode: work.routingCode as string,
              status: work.status as string,
              ...eachWork,
              summary: {
                totalQuantity: totalQuantity ?? "0",
                percent: percent ? Number(percent).toFixed(2) : "0",
                start: start ?? "0",
                end: end ?? "0",
                defectTotal: defectTotal ?? "0",
                todoQuantity: todoQuantity ?? "0",
                defect: defect as Record<
                  string,
                  {
                    defectQuantity: string;
                    reworkQuantity: string;
                    actualDefectQuantity: string;
                  }
                >,
              },
            },
          });
        });
      }

      return acc;
    }, []) ?? [];

  return (
    <WorkByEquipmentTableWrapper>
      <TableWrapper withBorder>
        <Table>
          <Thead>
            <Tr>
              <Th minWidth={40} width={4} seq={0}></Th>
              <Th minWidth={50} width={4} seq={1}>
                <Text fz="sm" fw={500} span>작업번호</Text>
              </Th>
              <Th minWidth={50} width={4} seq={2}>
                <Text fz="sm" fw={500} span>사외 외주 여부</Text>
              </Th>
              <Th minWidth={180} width={20} seq={3}>
                <Text fz="sm" fw={500} span>
                  라인명
                </Text>
                <Text fz="sm" fw={500} span>
                  설비명
                </Text>
              </Th>
              <Th minWidth={340} width={31} seq={4}>
                <Div>
                  <Text fz="sm" fw={500} span>
                    AI 예측 제품(공정코드)
                  </Text>
                </Div>
                <Div>
                  <Text fz="sm" fw={500} span>
                    제품(공정코드)
                  </Text>
                </Div>
                <Div>
                  <Text fz="sm" fw={500} span>
                    규격
                  </Text>
                </Div>
                <Div>
                  <Text fz="sm" fw={500} span>
                    진행률
                  </Text>
                </Div>
              </Th>
              <Th minWidth={280} width={31} seq={5}>
                <Text fz="sm" fw={500}>
                  <Text c={theme.colors?.blue?.[9]} span>
                    계획 수량
                  </Text>
                  {" / "}
                  <Text c={theme.colors?.orange?.[9]} span>
                    생산 잔량
                  </Text>
                </Text>
                <Text fz="sm" fw={500}>
                  <Text c={theme.colors?.teal?.[9]} span>
                    총생산 수량
                  </Text>
                  {" / "}
                  <Text c={theme.colors?.red?.[9]} span>
                    불량 수량
                  </Text>
                </Text>
              </Th>
              <Th minWidth={120} width={14} seq={6}>
                <Text fz="sm" fw={500} span>
                  완료수량
                </Text>
              </Th>
              {usedDefectCodes?.map((defectCode: any, i: number) => {
                return (
                  <Th
                    minWidth={120}
                    width={14}
                    key={defectCode.code}
                    seq={7 + i}
                  >
                    <Text fz="sm" fw={500} span>
                      {defectCode.name}
                    </Text>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {flattedRows.map((row, index) => {
              return (
                <WorkRow
                  key={row.work?.id ?? index}
                  item={row}
                  onSave={reload}
                  defectCodes={usedDefectCodes.map(
                    (defect: any) => defect.code
                  )}
                  pageKey="equipment"
                ></WorkRow>
              );
            })}
          </Tbody>
        </Table>
      </TableWrapper>
      <PageWrapper
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={totalPage as number}
          size="lg"
          radius="sm"
        />
      </PageWrapper>
    </WorkByEquipmentTableWrapper>
  );
};

const TableWrapper = styled<any>(Paper)`
  width: 100%;
`;
const WorkByEquipmentTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
`;

const Thead = styled.thead`
  width: 100%;
`;
const Tbody = styled.tbody`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Th = styled.th<{ minWidth?: number; width?: number; seq?: number }>`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : `auto`)};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  padding: 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: pre-line;
  word-break: break-all;
  border: 0.0625rem solid ${theme.colors?.gray?.[3]};
`;

const Div = styled.div`
  display: flex;
  width: 50%;
`;
