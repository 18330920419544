import { customFunctions } from "@/config/customFunction";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { DefectTypeItem } from "../ui/DefectTypeSet";

type PageKeyType = 'equipment' | 'plan' | 'operationOutsource';

interface WorkTableHeadProps {
    defectTypes: DefectTypeItem[] | null;
    pageKey: PageKeyType;
    formReset?: boolean;
}

const baseFixedColumns = 5;
const baseFixedPlanColumns = 7;

export const WorkTableHead = ({ defectTypes, pageKey, formReset }: WorkTableHeadProps) => {
    const isPlan = pageKey === 'plan';

    let numRefs = isPlan ? baseFixedPlanColumns : baseFixedColumns;
    if (customFunctions.ADD_WORK_ID_COLUMN) numRefs++;
    if (customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN) numRefs++;
    if (customFunctions.ADD_DESCRIPTION_COLUMN) numRefs++;

    const refs = useRef<(HTMLTableHeaderCellElement | null)[]>([]);
    const [widths, setWidths] = useState<number[]>(new Array(numRefs).fill(0));

    const state = useWorkState();
    const actions = useWorkActions();
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const publish = usePub();

    useEffect(() => {
        setAllChecked(state.works.length === state.tempWorks.length);
    }, [state.works, state.tempWorks]);

    useEffect(() => {
        const newWidths = refs.current.map((ref) => (ref ? ref.offsetWidth : 0));
        setWidths(newWidths);
    }, [state.date, state.works, state.tempWorks]);

    useSub('check', () => {
        setAllChecked(state.works.length === state.tempWorks.length);
    });

    useSub('unCheck', () => {
        setAllChecked(false);
    });

    useEffect(() => {
        if (formReset) {
            setAllChecked(false);
        }
    }, [formReset, allChecked]);

    const calculateLeftWidth = (index: number) => {
        return widths.slice(0, index).reduce((acc, width) => acc + width, 0);
    };

    return (
        <thead>
            <tr>
                <Th leftWidth={0} ref={(el) => (refs.current[0] = el)}>
                    <Flex justify="center">
                        <Checkbox
                            checked={allChecked}
                            onClick={(e) => {
                                if (e.currentTarget.checked) {
                                    publish('checkAll');
                                    actions.setWorks((prevWorks) => state.tempWorks);
                                    setAllChecked(true);
                                }
                                else {
                                    publish('unCheckAll');
                                    actions.setWorks((prevWorks) => []);
                                    setAllChecked(false);
                                }
                            }}
                        />
                    </Flex>
                </Th>
                {isPlan && (
                    <Th leftWidth={calculateLeftWidth(1)} ref={(el) => (refs.current[1] = el)}>
                        <Text fz="sm" fw={500} c="black" ta="center" span>
                            작업순서
                        </Text>
                    </Th>
                )}
                {isPlan && (
                    <Th leftWidth={calculateLeftWidth(2)} ref={(el) => (refs.current[2] = el)}>
                        <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                            <Text fz="sm" fw={500} c="black" ta="center" span>
                                최근 작업일
                            </Text>
                        </Flex>
                    </Th>
                )}
                {customFunctions.ADD_WORK_ID_COLUMN && (
                    <Th leftWidth={calculateLeftWidth(3)} ref={(el) => (refs.current[3] = el)}>
                        <Text fz="sm" fw={500} c="black" ta="center" span>
                            작업번호
                        </Text>
                    </Th>
                )}
                {customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN && (
                    <Th leftWidth={calculateLeftWidth(4)} ref={(el) => (refs.current[4] = el)}>
                        <Text fz="sm" fw={500} c="black" ta="center" span>
                            사외 외주 여부
                        </Text>
                    </Th>
                )}
                <Th leftWidth={calculateLeftWidth(5)} ref={(el) => (refs.current[5] = el)}>
                    <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
                        <Text fz="sm" fw={500} c="black" ta="center">
                            라인명
                        </Text>
                        <Text fz="sm" fw={500} c="black" ta="center">
                            설비명
                        </Text>
                    </Flex>
                </Th>
                {customFunctions.ADD_DESCRIPTION_COLUMN && (
                    <Th leftWidth={calculateLeftWidth(6)} ref={(el) => (refs.current[6] = el)}>
                        <Flex justify="center" align="center">
                            <Text fz="sm" fw={500} c="black" ta="center" span>
                                비고
                            </Text>
                        </Flex>
                    </Th>
                )}
                <Th leftWidth={calculateLeftWidth(7)} ref={(el) => (refs.current[7] = el)}>
                    <Flex align="center" direction="column" wrap="wrap">
                        <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                            <Text fz="sm" fw={500} c="black">
                                AI 예측 품목(라우팅코드)
                            </Text>
                            <Text fz="sm" fw={500} c="black">
                                품목명(품목코드)
                            </Text>
                            <Text fz="sm" fw={500} c="black" ta="center">
                                규격
                            </Text>
                            <Text fz="sm" fw={500} c="black" ta="center">
                                진행률
                            </Text>
                        </Flex>
                    </Flex>
                </Th>
                <Th leftWidth={calculateLeftWidth(8)} ref={(el) => (refs.current[8] = el)}>
                    <Flex align="center" direction="column" wrap="wrap">
                        <Flex direction="column" align="flex-start" gap="sm" wrap="wrap">
                            <Flex gap="xs">
                                <Text fz="sm" fw={500} c={theme.colors?.blue?.[9]}>
                                    계획수량
                                </Text>
                                <Text>/</Text>
                                <Text fz="sm" fw={500} c={theme.colors?.orange?.[9]}>
                                    생산 잔량
                                </Text>
                            </Flex>
                            <Flex gap="xs">
                                <Text fz="sm" fw={500} c={theme.colors?.teal?.[9]} ta="center">
                                    총생산수량
                                </Text>
                                <Text>/</Text>
                                <Text fz="sm" fw={500} c={theme.colors?.red?.[9]} ta="center">
                                    불량수량
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Th>
                <Th leftWidth={calculateLeftWidth(9)} ref={(el) => (refs.current[9] = el)}>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        완료수량
                    </Text>
                </Th>
                {defectTypes?.map((defect) => (
                    <th key={defect.value}>
                        <Text fz="sm" fw={500} c="black" ta="center">
                            {defect.label}
                        </Text>
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const Th = styled.th<{ leftWidth?: number }>`
    position: sticky;
    background-color: white;
    border-right: 0.0625rem solid rgb(222, 226, 230);
    border-bottom: 0.0625rem solid rgb(222, 226, 230);
    left: ${(props) => props.leftWidth ?? 0}px;
`;