import { DEFECT_QUERY_KEY as siteQueryKey } from "@/api/useGetSitesQuery";
import { MasterInstance } from "@/instance/axios";
import { useMutation, useQueryClient } from "react-query";

const requestSiteFetcher = (params: any) => {
  return MasterInstance.sitesPost({
    sitesGetRequest: {
      ...params,
    },
  });
};
const useSiteMutation = () => {
  const queryClient = useQueryClient();

  //옵티미스틱 적용
  return useMutation((params?: Object) => requestSiteFetcher(params), {
    // Mutation 함수가 실행되기 전에 실행됩니다.
    onMutate: async (params) => {
      await queryClient.cancelQueries(siteQueryKey);
      const previousItems = queryClient.getQueryData(siteQueryKey);
      queryClient.setQueryData(siteQueryKey, (old: any) => {
        return {
          ...old,
          data: { ...old.data, rows: [...old.data.rows, params] },
        };
      });
      return { previousItems };
    },
    // onMutate 함수에서 반환된 값은 변이 실패시 onError 및 on Settled 함수에 모두 전달
    // 실패시 이전의 데이터를 넣어줌
    onError: (err, params, context: any) => {
      queryClient.setQueryData(siteQueryKey, context.previousItems);
    },
    //  쿼리가 성공적으로 가져오거나 오류가 발생하여 데이터 또는 오류가 전달될 때마다 실행됩니다.
    onSettled: () => {
      queryClient.invalidateQueries([siteQueryKey]);
    },
  });
};
export default useSiteMutation;
